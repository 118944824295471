import React from "react";
import { Typography } from "@material-ui/core";

function CustomRange(props) {
const {lowerRange,upperRange,score}=props
  return (
    <div id="chartLenovo" >
      <div>
        <div style={{ border: "none", height: "32vh" }}>
          <Typography
            style={{ textAlign: "center", fontWeight: 500, fontSize: 14 }}
          >
            Peer Group Range
          </Typography>

          <div
            style={{
              borderRight: "2px dashed #5198E0",
              height: "43%",
              width: "1px",
              // marginLeft: "5%",
              position: "relative",
              top: "15%",
              right: "4px",
              padding: "0px 2px",
              backgroundColor: "white",
              color: "#5198E0",
              zIndex: 1,
              //pass the score value here
              marginLeft: `${
                4 + 0.9 * score
              }% `,
              display: "flex",
              justifyContent: "center",
            }}
          ></div>

          <div
            style={{
              borderLeft: "2px dashed #5198E0",
              height: "43%",
              width: "1px",
              // marginLeft: "5%",
              position: "relative",
              top: "-28%",
              padding: "0px 2px",
              backgroundColor: "white",
              color: "#5198E0",
              zIndex: 1,
              //pass the score value here
              marginLeft: `${
                4 + 0.9 * score
              }% `,
              display: "flex",
              justifyContent: "center",
            }}
          ></div>

          {/* ///////////////////////////// */}
          <div
            style={{
              borderLeft: "2px solid #5198E0",
              height: "16%",
              width: "1px",
              // marginLeft: "5%",
              position: "relative",
              top: "-30%",
              padding: "0px 2px",
              backgroundColor: "white",
              zIndex: 1,
              //pass the score value here
              marginLeft: `${
                4 + 0.9 * score
              }% `,
            }}
          >
            <span
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#5198E0",
                position: "absolute",
                bottom: 0,
                marginLeft: "2px",
              }}
            >
              <Typography
                style={{ color: "white", fontSize: "12px", padding: "1px 6px" }}
              >
                {`${score}%`}
              </Typography>
            </span>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              top: "-92%",
            }}
          >
            <div
              style={{
                borderLeft: "1px solid lightgrey",
                borderRight: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                height: "25%",
                margin: "0% 5%",
              }}
            ></div>
            <div
              style={{
                borderLeft: "1px solid lightgrey",
                borderRight: "1px solid lightgrey",
                // borderTop: "1px solid grey",
                height: "25%",
                margin: "0% 5%",
              }}
            ></div>
            <div
              style={{
                zIndex: 0,
                // border: "1px solid lightgrey",
                backgroundColor: "#8246AF",
                opacity: 1,
                height: "40%",
                margin: "0% 5%",
                position: "relative",
                //pass the value range from left
                marginLeft: `${
                  4 +
                  0.9 *
                    lowerRange
                }% `,
                //pass the value range from right
                marginRight: `${
                  5 +
                  0.9 *
                    (100 -
                      upperRange)
                }% `,
                bottom: "45%",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0% 2% 0% 4%",
                position: "relative",
                bottom: "23%",
              }}
            >
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                0%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                10%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                20%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                30%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                40%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                50%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                60%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                70%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                80%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                90%
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                100%
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomRange;
