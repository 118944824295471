
  export function set_loader(){
    return {
      type: 'SET_LOADER',
      payload:true
    };
  }

  export function unset_loader(){
    return {
      type: 'UNSET_LOADER',
      payload:false
    };
  }
