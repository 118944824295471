import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import RadioButton from "../../UI/radioButton/radioButton";
import { makeStyles } from "@material-ui/core/styles";
import notebook from "../../../images/notebook.svg";
import SurveySuccess from "../../../images/SurveySuccess.svg";
// import ScrollToTop from '../scrollto/ScrollToTop';
// import ScrollToBottm from '../scrollto/ScrollToBottom';

import { CardContent, Grid, TextField } from "@material-ui/core";
import CustomButton from "../../UI/button/button";

const checkBoxStyles = makeStyles((theme) => ({
  root: {
    "&.Mui-checked": {
      color: "#3374B9",
    },
  },
  qno: { marginRight: "10px" },
}));

function RiskSurvey(props) {
  const classes = checkBoxStyles();
  const { corporateSurvey } = props;
  // const {login,asessment_id,updateMaturityMatrix,maturityMatrix,getMaturityMatrix}=props;
  const [questions, setQuestions] = useState([
    {
      order: 1,
      category: "Product responsibility",
      question:
        "How concerned are you about controversies linked to responsible R&D in our company?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 2,
      category: "Resource use",
      question:
        "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 3,
      category: "Workforce",
      question:
        "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
      answers: [
        ["Strongly disagree", 1],
        ["Disagree", 2],
        ["Neutral", 3],
        ["Agree", 4],
        ["Strongly agree", 5],
      ],
    },
    {
      order: 4,
      category: "Workforce",
      question:
        "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 5,
      category: "Human rights",
      question:
        "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
      answers: [
        ["Not concerned at all", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 6,
      category: "Management",
      question:
        "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 7,
      category: "Workforce",
      question:
        "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 8,
      category: "Workforce",
      question:
        "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
      answers: [
        ["Never", 5],
        ["Rarely", 4],
        ["Sometimes", 3],
        ["Often", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 9,
      category: "Community",
      question:
        '"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing."',
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 10,
      category: "Community",
      question:
        "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Greatly concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 11,
      category: "Shareholders",
      question:
        "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 12,
      category: "Community",
      question:
        'How much do you agree with the following statement: "There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.',
      answers: [
        ["Strongly Disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly Agree", 1],
      ],
    },
    {
      order: 13,
      category: "Community",
      question:
        "How often do you come across controversies related to patents and intellectual property infringements?",
      answers: [
        ["Rarely", 5],
        ["Occasionally", 4],
        ["Sometimes", 3],
        ["Frequently", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 14,
      category: "Community",
      question:
        "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 15,
      category: "Shareholders",
      question:
        "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
      answers: [
        ["Not concerned at all", 5],
        ["Slightly concerned", 4],
        ["Moderately concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 16,
      category: "Shareholders",
      question:
        "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 17,
      category: "Product responsibility",
      question:
        "How often do you believe that access to a product has been associated with controversies?",
      answers: [
        ["Never", 5],
        ["Rarely", 4],
        ["Sometimes", 3],
        ["Often", 2],
        ["Almost always", 1],
      ],
    },
    {
      order: 18,
      category: "Product responsibility",
      question:
        "How many controversies have been linked to employees or customers in the past year?",
      answers: [
        ["None", 5],
        ["A few", 4],
        ["Some", 3],
        ["Many", 2],
        ["A lot", 1],
      ],
    },
    {
      order: 19,
      category: "Product responsibility",
      question:
        "How concerned are you about the number of controversies linked to customer health and safety at this company?",
      answers: [
        ["Not concerned at all", 5],
        ["Mildly concerned", 4],
        ["Somewhat concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 20,
      category: "Community",
      question:
        'How strongly do you agree with the statement: "The company should prioritize avoiding controversies in countries that do not respect human rights principles"?',
      answers: [
        ["Strongly agree", 5],
        ["Agree", 4],
        ["Neutral", 3],
        ["Disagree", 2],
        ["Strongly disagree", 1],
      ],
    },
    {
      order: 21,
      category: "Human rights",
      question:
        "How concerned are you about the number of controversies linked to human rights issues within the company?",
      answers: [
        ["Not at all concerned", 5],
        ["Slightly concerned", 4],
        ["Somewhat concerned", 3],
        ["Very concerned", 2],
        ["Extremely concerned", 1],
      ],
    },
    {
      order: 22,
      category: "Product responsibility",
      question:
        "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
    {
      order: 23,
      category: "Product responsibility",
      question:
        "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
      answers: [
        ["Strongly disagree", 5],
        ["Disagree", 4],
        ["Neutral", 3],
        ["Agree", 2],
        ["Strongly agree", 1],
      ],
    },
  ]);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
  const [currenQuestion, setCurrentQuestion] = useState([]);
  const [currenAnswer, setCurrentanswer] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [collect, setCollect] = useState({
    question_id: "",
    value: "",
    comment: "",
  });
  // const [collect, setCollect] = useState({});
  const [helperText, setHelperText] = useState("");

  const checkBoxChange = (e, i, j) => {
    const { name, id, checked } = e.target;
    if (checked) {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] + 1 });
      } else setCollect({ ...collect, [name]: 1 });
    } else {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] - 1 });
      } else alert("NEGATIVE");
    }
  };

  const addSurvey = (e, i) => {
    const { name, id, checked, value } = e.target;
    // console.log('va;',i,value,name,id,checked)
    setCollect({ ...collect, [`${i}`]: id });
    // }
  };

  const calculate = () => {
    // console.log(props.setCorporateAns)
    if (Object.keys(collect).length === 5) {
      props.setCorporateAns(collect);
      props.history.push("/risk_evaluator");
    } else {
      setHelperText("completely fill the survey..");
    }
  };

  // console.log('props survey up',localStorage.getItem('user_token'),localStorage.getItem('organization_id'))

  useEffect(() => {
    props.getCorporateQuestions(
      localStorage.getItem("user_token"),
      localStorage.getItem("organization_id")
    );
    props.getAssignedQuestions(
      localStorage.getItem("user_token"),
      localStorage.getItem("organization_id")
    );
  }, []);

  useEffect(() => {
    setCurrentQuestionId(
      corporateSurvey.corporateAnswers?.answers?.length === 23
        ? 23
        : corporateSurvey.corporateAnswers?.answers?.length
        ? corporateSurvey.corporateAnswers?.answers?.length - 1
        : corporateSurvey.corporateAnswers?.answers?.length
    );
  }, [corporateSurvey.corporateAnswers?.answers?.length]);
  useEffect(() => {
    corporateSurvey.corporateQuestions.length > 0 &&
      setCurrentQuestion([
        corporateSurvey.corporateQuestions[currentQuestionId],
      ]);
    corporateSurvey.corporateAnswers?.answers &&
      setCurrentanswer([
        corporateSurvey.corporateAnswers?.answers &&
          corporateSurvey.corporateAnswers?.answers[currentQuestionId],
      ]);

    if (
      corporateSurvey.corporateAnswers?.answers &&
      corporateSurvey?.corporateAnswers?.answers[currentQuestionId]
    ) {
      setCollect({
        ...collect,
        ...corporateSurvey?.corporateAnswers?.answers[currentQuestionId],
      });
    } else {
      setCollect({ question_id: "", value: "", comment: "" });
    }
  }, [
    currentQuestionId,
    corporateSurvey?.corporateQuestions,
    corporateSurvey?.corporateAnswers?.answers,
  ]);

  useEffect(() => {
    if (
      corporateSurvey.corporateQuestions.length ===
        corporateSurvey.corporateAnswers?.answers?.length &&
      23 === currentQuestionId
    ) {

      setIsSurveyCompleted(true);
    }
  }, [
    currentQuestionId,
    corporateSurvey.corporateAnswers?.answers?.length,
    corporateSurvey.corporateQuestions.length,
  ]);
  // useEffect(() => {
  //   console.log(corporateSurvey.corporateQuestions.length);
  //   console.log(corporateSurvey.corporateAnswers?.answers?.length);
  //   if (
  //     corporateSurvey.corporateQuestions.length ===
  //       corporateSurvey.corporateAnswers?.answers?.length &&
  //     23 === currentQuestionId 
  //   ) {
  //     console.log('terue')
  //     setIsSurveyCompleted(true);
  //   }
  // }, [currentQuestionId,
  //   corporateSurvey.corporateAnswers?.answers?.length,
  //   corporateSurvey.corporateQuestions.length,
  // ]);

  useEffect(() => {
    return () => {
      setCollect({ question_id: "", value: "", comment: "" });
    };
  }, []);
  const handleNext = () => {
    setCurrentQuestionId((prev) => (prev <= 23 ? prev + 1 : 23));
  };
  const handlePrevious = () => {
    props.getAssignedQuestions(
      localStorage.getItem("user_token")
        ? localStorage.getItem("user_token")
        : props?.login?.token && props?.login?.token,
      localStorage.getItem("organization_id")
        ? localStorage.getItem("organization_id")
        : props?.login?.organization_id && props?.login?.organization_id
    );
    setCurrentQuestionId((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleRedoSurvey = () => {
    setCurrentQuestionId(0);
    setIsSurveyCompleted(false);
  };

  return (
    <Grid container md={12}>
      <Grid md={2} style={{ marginTop: 10 }}>
        <img
          src={notebook}
          alt="notebook"
          // onClick={toggleNotebookDrawer}
          style={{ marginTop: 50, cursor: "pointer", paddingLeft: 80 }}
        />
      </Grid>
      <Grid md={7}>
        <Card
          variant="outlined"
          style={{
            backgroundColor: "#FFFFFF",
            height: "80vh",
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" style={{ fontWeight: "600" }}>
                  Survey Section
                </Typography>
              </Grid>
            </Grid>
            {!isSurveyCompleted ? (
              <>
                <div className="scroll" style={{ height: "63vh" }}>
                  <Grid
                    container
                    style={{ marginTop: "25px", marginBottom: "25px" }}
                  >
                    <Grid item xs={12} md={12} sm={12}>
                      <Card variant="outlined">
                        <CardContent style={{ height: "100%" }}>
                          <Grid sm={12} md={12} lg={12} spacing={1}>
                            {currenQuestion &&
                              currenQuestion?.map((que, i) => {
                                return (
                                  <div key={i}>
                                    <br />
                                    <Grid
                                      container
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      style={{ width: "100%" }}
                                    >
                                      <Grid item md={1}>
                                        <Typography>
                                          Q{currentQuestionId + 1}.
                                        </Typography>
                                      </Grid>
                                      <Grid item md={11}>
                                        <Typography>{que?.question}</Typography>
                                        <br></br>
                                        <hr></hr>
                                        <FormControl on component="fieldset">
                                          <RadioGroup name={que?._id + ""}>
                                            {que?.answers.map((ans, j) => {
                                              return (
                                                <div key={j}>
                                                  <FormControlLabel
                                                    control={
                                                      <RadioButton
                                                        checked={
                                                          ans[1] ===
                                                          collect.value
                                                        }
                                                        id={ans[1] + ""}
                                                        value={j}
                                                      />
                                                    }
                                                    label={ans[0]}
                                                    onChange={(e) => {
                                                      setCollect((prev) => {
                                                        return {
                                                          ...prev,
                                                          question_id: que?._id,
                                                          value: ans[1],
                                                        };
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })}
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </div>
                                );
                              })}
                          </Grid>
                        </CardContent>
                      </Card>
                      {/* <Grid container spacing={1} style={{ marginTop: "25px" }}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Add A comment</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="commentText"
                            id="standard-multiline-static"
                            multiline
                            rows={2}
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={collect.comment}
                            onChange={(event) =>
                              setCollect((prev) => ({
                                ...prev,
                                comment: event.target.value,
                              }))
                            }
                          />
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ padding: "15px", float: "right" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      handlePrevious();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={collect.value === ""}
                    onClick={() => {
                      props.submitAnswer(
                        localStorage.getItem("user_token")
                          ? localStorage.getItem("user_token")
                          : props?.login?.token && props?.login?.token,
                        localStorage.getItem("organization_id")
                          ? localStorage.getItem("organization_id")
                          : props?.login?.organization_id &&
                              props?.login?.organization_id,
                        collect.question_id,
                        collect.value
                      );
                      handleNext();
                      currentQuestionId === 22 && setIsSurveyCompleted(true);
                    }}
                  >
                    {currentQuestionId === 22 ? "Submit" : "Submit & Next"}
                  </Button>
                </Grid>
              </>
            ) : (
              <div className="scroll" style={{ height: "70vh" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "100px",
                  }}
                >
                  <img
                    style={{ maxWidth: "400px", maxHeight: "400px" }}
                    src={SurveySuccess}
                    alt="survey_success_vector"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#0F0F0F",
                        paddingBottom: "13px",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "500",
                        letterSpacing: "0.0015rem",
                        textTransform: "capitalize",
                      }}
                    >
                      The survey has been Saved.
                    </Typography>
                    <Typography
                      style={{
                        color: "#0F0F0F",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        letterSpacing: "0.0025em",
                        textTransform: "capitalize",
                      }}
                    >
                      If you wish to redo the survey please use the redo button.
                      Or go back to the task section.
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <CustomButton
                    style={{ textTransform: "none", fontFamily: "Poppins" }}
                    variant="contained"
                    color="primary"
                    onClick={handleRedoSurvey}
                  >
                    Redo Survey
                  </CustomButton>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    // <div>
    //   {/* <Card style={{ padding: '5px', margin: '5px' }}> */}

    //     {questions.map((que, i) => {
    //       return (
    //         <div key={i}>
    //           <br />
    //           <Grid container>
    //             <Grid item md={1}>
    //               <Typography className={classes.qno}>Q{i + 1}.</Typography>
    //             </Grid>
    //             <Grid item md={11}>
    //               <Typography >{que.name}</Typography>
    //               <FormControl on component="fieldset">
    //             <RadioGroup name={que._id + ''}>
    //               {que.ans.map((ans, j) => {
    //                 return (
    //                   <div key={j}>
    //                     <FormControlLabel
    //                       value={j + ''}
    //                       control={
    //                         // que.type === 'check' ? (
    //                         //   <Checkbox
    //                         //     className={clsx(classes.root, classes.checked)}
    //                         //     onChange={(e) => {
    //                         //       checkBoxChange(e, i, j);
    //                         //     }}
    //                         //     name={que.id + ''}
    //                         //     id={ans[1] + ''}
    //                         //   />
    //                         // ) : (
    //                           <RadioButton id={ans[0] + ''} />
    //                         // )
    //                       }
    //                       label={ans[0]}
    //                       onChange={(e) => {
    //                         addSurvey(e, i);
    //                       }}
    //                     />
    //                   </div>
    //                 );
    //               })}
    //             </RadioGroup>
    //           </FormControl>
    //           <hr></hr>

    //             </Grid>
    //           </Grid>

    //         </div>
    //       );
    //     })}
    //     <span
    //       style={{ color: 'red', fontSize: 'large', paddingBottom: '25px' }}
    //     >
    //       {helperText}
    //     </span>
    //     <br /> <br />
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       style={{float:'right',margin:'0px 20px 20px'}}
    //       onClick={() => {
    //         // charting();
    //         calculate()
    //       }}
    //     >
    //       Submit
    //     </Button>
    //   {/* </Card> */}
    // </div>
  );
}

export default RiskSurvey;
