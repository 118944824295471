import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import root from "../reducers/root";
import thunk from "redux-thunk";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
});

const store = createStore(root, composeEnhancers(applyMiddleware(thunk)));

export default store;