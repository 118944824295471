import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import RouterCont from "./containers/router/routerCont";

const theme = createTheme({
  typography: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterCont />
      </ThemeProvider>
    </>
  );
}

export default App;
