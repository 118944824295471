export const corporateSurveyQuestions = [
  {
    "order": 1,
    "category": "Product responsibility",
    "question": "How concerned are you about controversies linked to responsible R&D in our company?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 2,
    "category": "Resource use",
    "question": "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 3,
    "category": "Workforce",
    "question": "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
    "answers": [
      ["Strongly disagree", 1],
      ["Disagree", 2],
      ["Neutral", 3],
      ["Agree", 4],
      ["Strongly agree", 5]
    ]
  },
  {
    "order": 4,
    "category": "Workforce",
    "question": "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
    "answers": [
      ["Strongly Disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly Agree", 1]
    ]
  },
  {
    "order": 5,
    "category": "Human rights",
    "question": "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
    "answers": [
      ["Not concerned at all", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 6,
    "category": "Management",
    "question": "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
    "answers": [
      ["Strongly disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly agree", 1]
    ]
  },
  {
    "order": 7,
    "category": "Workforce",
    "question": "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 8,
    "category": "Workforce",
    "question": "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
    "answers": [
      ["Never", 5],
      ["Rarely", 4],
      ["Sometimes", 3],
      ["Often", 2],
      ["Almost always", 1]
    ]
  },
  {
    "order": 9,
    "category": "Community",
    "question": "\"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing.\"",
    "answers": [
      ["Strongly Disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly Agree", 1]
    ]
  },
  {
    "order": 10,
    "category": "Community",
    "question": "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Greatly concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 11,
    "category": "Shareholders",
    "question": "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
    "answers": [
      ["Strongly Disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly Agree", 1]
    ]
  },
  {
    "order": 12,
    "category": "Community",
    "question": "How much do you agree with the following statement: \"There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.",
    "answers": [
      ["Strongly Disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly Agree", 1]
    ]
  },
  {
    "order": 13,
    "category": "Community",
    "question": "How often do you come across controversies related to patents and intellectual property infringements?",
    "answers": [
      ["Rarely", 5],
      ["Occasionally", 4],
      ["Sometimes", 3],
      ["Frequently", 2],
      ["Almost always", 1]
    ]
  },
  {
    "order": 14,
    "category": "Community",
    "question": "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 15,
    "category": "Shareholders",
    "question": "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
    "answers": [
      ["Not concerned at all", 5],
      ["Slightly concerned", 4],
      ["Moderately concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 16,
    "category": "Shareholders",
    "question": "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
    "answers": [
      ["Strongly disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly agree", 1]
    ]
  },
  {
    "order": 17,
    "category": "Product responsibility",
    "question": "How often do you believe that access to a product has been associated with controversies?",
    "answers": [
      ["Never", 5],
      ["Rarely", 4],
      ["Sometimes", 3],
      ["Often", 2],
      ["Almost always", 1]
    ]
  },
  {
    "order": 18,
    "category": "Product responsibility",
    "question": "How many controversies have been linked to employees or customers in the past year?",
    "answers": [
      ["None", 5],
      ["A few", 4],
      ["Some", 3],
      ["Many", 2],
      ["A lot", 1]
    ]
  },
  {
    "order": 19,
    "category": "Product responsibility",
    "question": "How concerned are you about the number of controversies linked to customer health and safety at this company?",
    "answers": [
      ["Not concerned at all", 5],
      ["Mildly concerned", 4],
      ["Somewhat concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 20,
    "category": "Community",
    "question": "How strongly do you agree with the statement: \"The company should prioritize avoiding controversies in countries that do not respect human rights principles\"?",
    "answers": [
      ["Strongly agree", 5],
      ["Agree", 4],
      ["Neutral", 3],
      ["Disagree", 2],
      ["Strongly disagree", 1]
    ]
  },
  {
    "order": 21,
    "category": "Human rights",
    "question": "How concerned are you about the number of controversies linked to human rights issues within the company?",
    "answers": [
      ["Not at all concerned", 5],
      ["Slightly concerned", 4],
      ["Somewhat concerned", 3],
      ["Very concerned", 2],
      ["Extremely concerned", 1]
    ]
  },
  {
    "order": 22,
    "category": "Product responsibility",
    "question": "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
    "answers": [
      ["Strongly disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly agree", 1]
    ]
  },
  {
    "order": 23,
    "category": "Product responsibility",
    "question": "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
    "answers": [
      ["Strongly disagree", 5],
      ["Disagree", 4],
      ["Neutral", 3],
      ["Agree", 2],
      ["Strongly agree", 1]
    ]
  }
]
