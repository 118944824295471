import {
  Box,
  Grid,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../UI/button/button";

function CorporateSurvey(props) {
  const useStyles = makeStyles(() => ({
    mainDiv: {
      padding: "5px 20px",
      minWidth: 700,
      maxWidth: 700,
      overflowY: "hidden",
    },
    headerText: {
      fontWeight: "600",
    },
    cardTypography: {
      fontWeight: "600",
    },

    addIcon: {
      color: "#DADBE6",
    },

    removeChipIcon: {
      display: "none",
    },
    tableHeader: {
      marginTop: "10px",
      backgroundColor: "#EBF1F8",
      padding: "5px",
      borderRadius: "8px",
      height: "15px",
    },
    tableData: {
      padding: "6px",
    },
    statusOrange: {
      padding: "3px",
      borderRadius: "50%",
      backgroundColor: "orange",
      width: "8px",
      height: "8px",
    },
    headerIcon: { color: "#fff", paddingLeft: "5px" },
  }));
  const classes = useStyles();
  const history = useHistory();
  const taskData = [
    {
      name: props?.corporateSurvey?.corporateAnswers?.organization_data?.name
        ? props.corporateSurvey.corporateAnswers.organization_data.name
        : "esg survey from bank",
      lastModified:
        props?.corporateSurvey?.corporateAnswers?.organization_data
          ?.last_modified === "NA"
          ? "NA"
          : moment(
              props?.corporateSurvey?.corporateAnswers?.organization_data
                ?.last_modified
            ).format("lll"),
      editedBy: props?.corporateSurvey?.corporateAnswers?.organization_data
        ?.edited_by
        ? props.corporateSurvey.corporateAnswers.organization_data.edited_by
        : "name  of editor",
      status: props?.corporateSurvey?.corporateAnswers?.organization_data
        ?.status
        ? props.corporateSurvey.corporateAnswers.organization_data.status
        : "Completed",
    },
  ];
  useEffect(() => {
    props.getAssignedQuestions(
      localStorage.getItem("user_token"),
      localStorage.getItem("organization_id")
    );
    // props.getCorporateQuestions(
    //   localStorage.getItem("user_token"),
    //   localStorage.getItem("organization_id")
    // );
  }, []);
  return (
    <div>
      <Grid>
        <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
          Task
        </Typography>
      </Grid>
      {/* <CustomButton
          style={{ width: "95%", marginTop: "15px", backgroundColor: "grey" }}
          onClick={() => {
            setCompaniesData((prev) => {
              prev.push(companyDetails);
              return [...prev];
            });
          }}
        >
          Add Company
        </CustomButton> */}
      <TableContainer style={{ padding: "10px 80px", marginTop: "25px" }}>
        <Table style={{ width: "100%" }}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableData}>Recent Task</TableCell>
              <TableCell className={classes.tableData} align="right">
                Last Modified
              </TableCell>
              <TableCell className={classes.tableData} align="right">
                Shared By
              </TableCell>
              <TableCell className={classes.tableData} align="right">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.corporateSurvey?.corporateAnswers?.organization_data ? (
              taskData.map((row, i) => (
                <TableRow
                  key={row.name + i}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(`/corporate_questions/survey`);
                  }}
                >
                  <TableCell
                    className={classes.tableData}
                    // component="th"
                    // scope="row"
                  >
                    {row.name}
                  </TableCell>

                  <TableCell className={classes.tableData} align="right">
                    {row.lastModified}
                  </TableCell>
                  <TableCell className={classes.tableData} align="right">
                    <Typography>{row.editedBy}</Typography>
                    {/* <CustomButton
                    onClick={() =>
                      props.isEvaluator
                        ? history.push("/risk_evaluator/company_details")
                        : history.push("/risk_analysis/company_details")
                    }
                    // onClick={() => setShareDialogOpen(true)}
                    style={{ backgroundColor: "white" }}
                  >
                    <Icon fontSize="small">more_vert</Icon>
                  </CustomButton> */}
                  </TableCell>
                  <TableCell className={classes.tableData} align="right">
                    <Typography
                      style={{
                        textAlign: "right",
                        diaplay: "inline-flex",
                        alighItems: "center",
                      }}
                    >
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                        p={1}
                        style={{
                          padding: "8px",
                          textAlign: "right",
                        }}
                        borderRadius="8px"
                      >
                        <Box
                          borderRadius="50%"
                          height={10}
                          width={10}
                          bgcolor={
                            row.status === "Completed"
                              ? "#5BB37F"
                              : row.status === "In proress"
                              ? "#FFC250"
                              : "#9FA1AF"
                          }
                        />
                        <Box
                          style={{
                            paddingLeft: "5px",
                            fontSize: "14px",
                            color:
                              row.status === "Completed"
                                ? "#5BB37F"
                                : row.status === "In proress"
                                ? "inherit"
                                : "#9FA1AF",
                          }}
                        >
                          {row.status}
                        </Box>
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Survey Assigned
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CorporateSurvey;
