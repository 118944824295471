import {
  Button,
  FormControl,
  Grid,
  Icon,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import HeatMap from "react-heatmap-grid";
///////////////
import { HeatMapGrid } from "react-grid-heatmap";
// import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';

function TopicWiseChart(props) {
  //     const [canva,setCanva]=useState(document.getElementById('matrix-chart'))
  //     useEffect(()=>{
  // console.log(document.getElementById('matrix-chart'))
  // setCanva(document.getElementById('matrix-chart'))
  //     },[])
  //   // Chart.register(MatrixController, MatrixElement);
  //        function isoDayOfWeek(dt) {
  //           let wd = dt.getDay(); // 0..6, from sunday
  //           wd = (wd + 6) % 7 + 1; // 1..7 from monday
  //           return '' + wd; // string so it gets parsed
  //         }
  //         function startOfToday() {
  //           const d = new Date();
  //           return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
  //         }

  //         function generateData() {
  //           const data = [];
  //           const end = startOfToday();
  //           let dt = new Date(new Date().setDate(end.getDate() - 365));
  //           while (dt <= end) {
  //             const iso = dt.toISOString().substr(0, 10);
  //             data.push({
  //               x: iso,
  //               y: isoDayOfWeek(dt),
  //               d: iso,
  //               v: Math.random() * 50
  //             });
  //             dt = new Date(dt.setDate(dt.getDate() + 1));
  //           }
  //           console.log('data matrix',data)
  //           return data;
  //         }

  //     const data = {
  //       datasets: [{
  //         label: 'My Matrix',
  //         data: generateData(),
  //         backgroundColor(c) {
  //           const value = c.dataset.data[c.dataIndex].v;
  //           const alpha = (10 + value) / 60;
  //           return `rgba(57, 255, 86 , ${alpha})`;
  //         },
  //         borderColor(c) {
  //           const value = c.dataset.data[c.dataIndex].v;
  //           const alpha = (10 + value) / 60;
  //           return `rgba(57, 255, 86 , ${alpha})`;
  //         },
  //         borderWidth: 1,
  //         hoverBackgroundColor: 'yellow',
  //         hoverBorderColor: 'yellowgreen',
  //         width(c) {
  //           const a = c.chart.chartArea || {};
  //           return (a.right - a.left) / 53 - 1;
  //         },
  //         height(c) {
  //           const a = c.chart.chartArea || {};
  //           return (a.bottom - a.top) / 7 - 1;
  //         }
  //       }]
  //     };
  //     const scales = {
  //       y: {
  //         type: 'time',
  //         offset: true,
  //         time: {
  //           unit: 'day',
  //           round: 'day',
  //           isoWeekday: 1,
  //           parser: 'i',
  //           displayFormats: {
  //             day: 'iiiiii'
  //           }
  //         },
  //         reverse: true,
  //         position: 'right',
  //         ticks: {
  //           maxRotation: 0,
  //           autoSkip: true,
  //           padding: 1,
  //           font: {
  //             size: 9
  //           }
  //         },
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //           tickLength: 0
  //         }
  //       },
  //       x: {
  //         type: 'time',
  //         position: 'bottom',
  //         offset: true,
  //         time: {
  //           unit: 'week',
  //           round: 'week',
  //           isoWeekday: 1,
  //           displayFormats: {
  //             week: 'MMM dd'
  //           }
  //         },
  //         ticks: {
  //           maxRotation: 0,
  //           autoSkip: true,
  //           font: {
  //             size: 9
  //           }
  //         },
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //           tickLength: 0,
  //         }
  //       }
  //     };
  //     const options = {
  //       aspectRatio: 5,
  //       plugins: {
  //         legend: true,
  //         tooltip: {
  //           displayColors: false,
  //           callbacks: {
  //             title() {
  //               return '';
  //             },
  //             label(context) {
  //               const v = context.dataset.data[context.dataIndex];
  //               return ['d: ' + v.d, 'v: ' + v.v.toFixed(2)];
  //             }
  //           }
  //         },
  //       },
  //       scales: scales,
  //       layout: {
  //         padding: {
  //           top: 10
  //         }
  //       }
  //     };
  //      const config = {
  //       type: 'matrix',
  //       data: data,
  //       options: options
  //     };
  //     const chart = new Chart(canva, {
  //       type: 'matrix',
  //       data: data,
  //       options: options
  //     });
  //     console.log('chart',chart)
  // const [graphDaat, setGraphData] = useState("");
  // const [x, setX] = useState("");
  // const [y, setY] = useState("");
  // useEffect(() => {
  //   setGraphData( props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.values);
  //   setX( props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.org_names);
  //   setY( props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.topic_names);
  // }, [graphDaat,x,y]);
  // console.log(graphDaat, x, y);
  // const xLabels = [
  //   "al ahli bank of kuwait",
  //   "gulf bank kscp",
  //   "kuwait international bank",
  //   "ahli united bank - kuwait",
  // ];
  // const yLabels = [
  //   "Wages or working conditions controversies",
  //   "Diversity and opportunity controversies",
  //   "Management compensation controversies count",
  //   "Public health controversies",
  //   "Consumer controversies",
  //   "Intellectual property controversies",
  //   "Customer health and safety controversies",
  //   "Shareholder rights controversies",
  //   "Anti-competition controversy",
  //   "Responsible marketing controversies",
  //   "Privacy controversies",
  //   "Business ethics  controversies",
  //   "Child labour controversies",
  //   "Critical countries controversies",
  //   "Strikes",
  //   "Tax fraud controversies",
  //   "Responsible R&D controversies",
  //   "Environmental controversies",
  //   "Human rights controversies",
  //   "Product access controversies",
  //   "Insider dealings controversies",
  //   "Employee health and safety controversies",
  //   "Accounting controversies count",
  // ];
  // const data = [
  //   [79.77430550257365, 71.49425269817483, 70.20676598035004, 70.4120668996512],
  //   [78.83680550257365, 71.43678138995993, 71.70634830446171, 70.8682847012484],
  //   [
  //     77.69097216924031, 73.93678145573057, 70.33207922531548,
  //     71.47902796494776,
  //   ],
  //   [
  //     77.62152767181396, 73.50574697297195, 71.51837840833161,
  //     71.57100725489737,
  //   ],
  //   [76.85763883590698, 70.80459752576104, 70.99623966456058, 71.6188365776281],
  //   [
  //     76.85763883590698, 71.35057455917885, 71.05054211915287,
  //     71.16261878445185,
  //   ],
  //   [76.57986108462016, 69.19540207961509, 69.7974092966333, 69.04709265668924],
  //   [
  //     76.47569441795349, 70.54597677033523, 71.45572172848503,
  //     69.81604042642691,
  //   ],
  //   [76.44097216924031, 68.47701125309385, 69.75146101949208, 71.7696828273748],
  //   [
  //     76.16319433848064, 71.23563200851967, 70.79991558141876,
  //     69.63943995966291,
  //   ],
  //   [76.02430550257365, 70.14367800745471, 70.8124469193002, 70.90507647112243],
  //   [
  //     75.81597216924031, 71.43678132418928, 71.32205426782593,
  //     69.29727663793838,
  //   ],
  //   [
  //     75.81597216924031, 69.88505725202889, 68.76566318521523,
  //     69.43708538535415,
  //   ],
  //   [75.71180550257365, 71.20689635441221, 70.5284034805489, 69.02501755939677],
  //   [
  //     75.19097216924031, 68.85057455917885, 69.22096815563384,
  //     69.81236121407144,
  //   ],
  //   [
  //     75.19097216924031, 71.37931014751565, 71.63533752364921,
  //     70.06990351055894,
  //   ],
  //   [
  //     75.12152767181396, 70.71839062920932, 70.58270584909539,
  //     70.40470855914994,
  //   ],
  //   [
  //     74.77430550257365, 70.20114918412834, 71.47660727249949,
  //     70.61810075368312,
  //   ],
  //   [
  //     74.07986108462016, 70.34482738889497, 70.41144441303454,
  //     69.61736490447527,
  //   ],
  //   [74.0104165871938, 71.86781593848919, 69.27944764636811, 69.64679836753189],
  //   [
  //     72.23958325386047, 66.12068939208984, 69.45070915891414,
  //     69.70198600550098,
  //   ],
  //   [71.75347216924031, 70.2873561464507, 68.21846183260581, 68.16776958575049],
  //   [
  //     70.36458325386047, 68.27586187165359, 69.23349952219722,
  //     70.71375930651398,
  //   ],
  // ];
  // const yLabels = [
  //   "Product access controversies",
  //   "Business ethics controversies",
  //   "Tax fraud controversies",
  //   "Insider dealings controversies",
  //   "Anti-competition controversy",
  //   "Intellectual property controversies",
  //   "Privacy controversies",
  //   "Public health  controversies",
  //   "Customer health and safety controversies",
  //   "Environmental controversies",
  //   "Diversity and opportunity controversies",
  //   "Employee health and safety controversies",
  //   "Critical countries controversies",
  //   "Human rights controversies",
  //   "Child labour controversies",
  //   "Management compensation  controversies count",
  //   "Wages or working conditions controversies",
  //   "Strikes",
  //   "Accounting controversies count",
  //   "Shareholder rights controversies",
  //   "Responsible R&D controversies",
  //   "Consumer controversies",
  //   "Responsible marketing controversies",
  // ];
  // const data = new Array(yLabels.length)
  //   .fill(0)
  //   .map(() =>
  //     new Array(xLabels.length)
  //       .fill(0)
  //       .map(() => Math.floor(Math.random() * 100))
  //   );
  // console.log("dt", data);
 const graphDaat =props?.riskEvaluator?.sentimentRiskWiseData
 ? props.riskEvaluator?.sentimentRiskWiseData?.values.map((values) => {
     return values;
   })
 : [];
 const x =  props?.riskEvaluator?.sentimentRiskWiseData
 ? props.riskEvaluator?.sentimentRiskWiseData?.org_names.map((org_names) => {
     return org_names;
   })
 : [];
 const y = props?.riskEvaluator?.sentimentRiskWiseData
 ? props.riskEvaluator?.sentimentRiskWiseData?.topic_names.map((topic_name) => {
     return topic_name;
   })
 : [];

  return (
    <Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        md={12}
      >
        <Grid item>
          <Typography>Topic wise</Typography>
        </Grid>
        <Grid item alignItems="center">
          <FormControl style={{ color: "#3374B9", width: "250px" }}>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "blue",
                borderRadius: "8px",
                height: "40px",
                marginTop: "15px",
              }}
            >
              Click to Expand
            </Button>
          </FormControl>
          <Icon style={{ marginTop: "22px" ,cursor:'pointer'}}>more_vert</Icon>
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          //   backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            width: "100%",
            // paddingRight:'185px'
          }}
        >
          {/* <HeatMap
         cellStyle={(background, value, min, max, data, x, y) => ({
          background: `rgba(66, 86, 244, ${1 - (max - value) / (max - min)})`,
          fontSize: "11px",
          position:'relative'
        })}
            xLabelsLocation="bottom"
            yLabelsLocation="left"
            yLabelsVisibility={true}
            xLabelWidth={180}
            yLabelWidth={60}
            xLabels={xLabels}
            yLabels={yLabels}
            data={data}
          /> */}
          {(
            <HeatMapGrid
              // data={ props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.values}
              // xLabels={props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.org_names}
              // yLabels={props?.riskEvaluator && props?.riskEvaluator?.sentimentRiskWiseData?.topic_names}
              data={ graphDaat && graphDaat}
              xLabels={x && x}
              yLabels={y && y}
              xLabelsStyle={(index) => ({
                color: "black",
                fontSize: "12px",
              })}
              yLabelsStyle={() => ({
                fontSize: "12px",
                lineHeight:'normal',
                width:'245px',
                height:'2.3rem',
                display:'grid',
                alignItems:'center',
                // wordBreak:'break',
                // textTransform: "uppercase",
                color: "black",
                // width: "100%",
              })}
              // cellRender={(x, y, value) => (
              //   <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
              // )}
              cellStyle={(_x, _y, ratio) => ({
                background: `rgb(12, 160, 44, ${ratio})`,
                fontSize: ".8rem",
                color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
              })}
              cellHeight="2.3rem"
              xLabelsPos="bottom"
              // onClick={(x, y) => alert(`Clicked (${x}, ${y})`)}
              yLabelsPos="left"
            />
          )}
          {/* <canvas id="matrix-chart" /> */}
          {/* <Chartr config={config} options={options} data={data} />  */}
        </div>
        {/* <Bar config={config}  data={data} /> */}
      </Grid>
    </Grid>
  );
}

export default TopicWiseChart;
