import { makeStyles } from '@material-ui/core';

const drawerWidth = 260;

const DrawerUseStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  listitems: {
    width: '90%',
    marginBottom: '8px',
  },
  list: {
    width: '90%',
    margin: '5%',
  },
  list2: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  head: {
    padding: 0,
    marginRight: 'auto',
    marginLeft: '5px',
    marginBottom: '5px',
  },
  iconButton: { color: '#0873d0' },
  link: { textDecoration: 'none' },
  img: { marginLeft: 12 },
}));

export default DrawerUseStyles;
