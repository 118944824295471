import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import PercentageDoughnutChart from "../../../UI/percentageDoughnut.js/percentageDoughnutChart";
import charge from "../../../../images/Folder.png";
import heart from "../../../../images/SelfAssessment.png";
function Assessment() {
  const accordionData = [
    {
      summary: `To what extent has your company assessed the risk of climate
      change and other external environmental & social factors to its
      materials sunpply chains ?`,
      description:
        "Our Self Assessment Score provides valuable insights into your ESG practices, helping you meet stakeholder expectations and work towards a more sustainable future.",
    },
    {
      summary: `To what extent has your company assessed the risk of climate change and other external environmental & social factors to its materials sunpply chains ?`,
      description:
        "Our Self Assessment Score provides valuable insights into your ESG practices, helping you meet stakeholder expectations and work towards a more sustainable future.",
    },
    {
      summary: `To what extent has your company assessed the risk of climate change and other external environmental & social factors to its materials supply chains ?`,
      description:
        "Our Self Assessment Score provides valuable insights into your ESG practices, helping you meet stakeholder expectations and work towards a more sustainable future.",
    },
  ];
  return (
    <div className="scroll" style={{ height: "54vh" }}>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Card style={{ padding: "5px", width: "69%" }}>
          <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
            Self Assessment Score
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            Our Self Assessment Score provides valuable insights into your ESG
            practices, helping you meet stakeholder expectations and work
            towards a more sustainable future.
          </Typography>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", marginTop: "15px" }}>
              <div style={{ width: "65%", display: "flex" }}>
                <img
                  src={heart}
                  alt="charge"
                  style={{ width: "40px", height: "40px" }}
                />

                <PercentageDoughnutChart
                  color="#9ABD8B"
                  emptyColor="#EBF6EF"
                  percentage={55}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" ,marginLeft: "11%"}}>
                <div
                  style={{
                    backgroundColor: "#9ABD8B",
                    borderRadius: "100%",
                    width: "12px",
                    height: "12px",
                  }}
                />{" "}
                <Typography style={{ marginLeft: "10px" }}>
                  Self Assessment Score
                </Typography>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "15px" }}>
              <div style={{ width: "65%", display: "flex" }}>
                <img
                  src={charge}
                  alt="charge"
                  style={{ width: "40px", height: "40px" }}
                />
                <PercentageDoughnutChart
                  color="#ECA270"
                  emptyColor="#F9EEED"
                  percentage={85}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center",marginLeft: "11%"}}>
                <div
                  style={{
                    backgroundColor: "#ECA270",
                    borderRadius: "100%",
                    width: "12px",
                    height: "12px",
                  }}
                />{" "}
                <Typography style={{ marginLeft: "10px" }}>
                    Public Disclosure Score
                </Typography>
              </div>
            </div>
          </div>
        </Card>
        <div style={{ width: "30%", padding: "5px" }}>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Deviation Percentage
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: "24px", padding: "16px 0px" }}
          >
            12%
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            The deviation may indicate a gap between a company's actual ESG
            practices and what is being publicly disclosed, highlighting the
            need for improved transparency and accountability.
          </Typography>
        </div>
      </div>
      <div>
        <Typography
          style={{ fontWeight: 600, fontSize: "15px", marginTop: "15px" }}
        >
          Additional Self Assessment Answers
        </Typography>
        {accordionData.map((data) => (
          <Accordion variant="elevation" elevation={0}>
            <AccordionSummary
              style={{ margin: 0 }}
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    backgroundColor: "#EBF1F8",
                    borderRadius: "50%",
                    color: "#3374B9",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                style={{ fontWeight: 500, fontSize: "14px", margin: 0 }}
              >
                {data.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "felx",
                flexDirection: "column",
                padding: "12px",
              }}
            >
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                {data.description}
              </Typography>{" "}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default Assessment;
