import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Paper,
  RadioGroup,
  Tab,
  TableCell,
  Tabs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../UI/button/button";
import TabPanel from "../../../UI/tabPanel/tabPanel";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextfield from "../../../UI/textfield/textfield";
import countryList from "../../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import tickGreen from "../../../../images/TickGreen.png";
import Add from "@material-ui/icons/Add";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { appTheme } from "../brmDetaails";
import RiskAnalysisRightTab from "../../riskAnalysisRightTab/riskAnalysisRightTab";
import { corporateSurveyQuestions } from "../../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";
import RadioButton from "../../../UI/radioButton/radioButton";
import SentimentAnalysisRightTab from "../../sentimentAnalysisRightTab/sentimentAnalysisRightTab";
import RiskSummaryTab from "../../riskSummaryTab/riskSummaryTab";
import { addMember, viewMember } from "../../../../redux/actions/member/member";
import CustomCheckBox from "../../../UI/checkBox/checkBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import SurveyQuestions from "../../surveyQuestions/surveyQuestions";
import ControverciesQuestions from "../../controverciesQuestions/controverciesQuestions";
import EsgDeclaration from "../../esgDeclaration/esgDeclaration";
import DataBreakdown from "../DataBreakdown";
import Controversy from "../../esgControversy/controversy";
import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
import PeerDocuments from "../../publicInfo/peerDocuments";
import PublicReports from "../../publicInfo/publicReports";
import ApiConnections from "../../publicInfo/apiConnections";
import Documents from "../../publicInfo/documents";

export const brmRightStyles = makeStyles((theme) => ({
  cardContainerDefault: {
    width: "100%",
    height: "100px",
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },

  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },

  cardTypography: {
    fontWeight: "600",
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12 },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typographyshare: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
}));

function BrmDataRight(props) {
  const location = useHistory();
  // console.log(
  //   "🚀 ~ file: brmDataRight.js:130 ~ location",
  //   window.location.origin
  // );

  const classes = brmRightStyles();
  const {
    brmData,
    riskEvaluator,
    riskTabNo,
    setRiskTabNo,
    // organizaitonDetails,
    setOrganizationDetails,
    login,
  } = props;

  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    no_of_employees: "",
    address: [
      { landMark: "", state: "", pinCode: "", companyAddress: "", country: "" },
    ],
  });
  const initialPeer = {
    name: "",
    somain: "",
  };

  useEffect(() => {
    brmData?.organizationDetails[0] && setOrganizaitonDetailsObject(brmData?.organizationDetails[0]);
    setCompanyLogoURI(brmData?.organizationDetails[0]?.logo?.url);
  }, [brmData?.organizationDetails]);

  const [peerData, setPeerData] = useState([]);
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [userId, setUserId] = useState("");

  const [role, setRole] = useState("");
  const [reportsTab, setReportsTab] = useState(0);

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      // uploadUserSheet(login.token, login.current_organisation, file);
      setAddNewUserDialogOpen(false);
    }
  };
  const stakeHolderCategoryList = [
    "Board of Directors",
    "Executive Team",
    "Employees",
    "Investors & Shareholders",
    "Suppliers & Vendors",
    "Consultants",
    "Customers",
    "Government Agencies",
  ];
  const reportData = [
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
    {
      name: "file name",
      heading: "introduction to the art of future",
      link: "link",
    },
  ];
  const breakDown = [
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        { question: "Growth", FY2020: "2330", FY2021: "2331", FY2022: "2332" },
        {
          question: "cross region",
          FY2020: "2330",
          FY2021: "2331",
          FY20202: "2332",
        },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "sub question",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
            {
              question: "time",
              FY2020: "2330",
              FY2021: "2331",
              FY2022: "2332",
            },
          ],
        },
      ],
    },
    {
      topic: "Our people",
      data: [
        { question: "Europe", FY2020: "2330", Fy2021: "2331", Fy20202: "2332" },
      ],
      subTopics: [
        {
          topic: "Our people",
          data: [
            {
              question: "Europe",
              FY2020: "2330",
              Fy2021: "2331",
              Fy20202: "2332",
            },
          ],
        },
      ],
    },
  ];
  async function handleAddUser() {
    props.addMember(
      login?.token,
      login?.bankId,
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id"),
      name,
      email,
      role,
      category,
      type,
      window.location.origin
    );
    setAddNewUserDialogOpen(false);
    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData.organizationDetails[0]._id
    //     ? brmData.organizationDetails[0]._id
    //     : localStorage.getItem("organization_id")
    // );
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // setMembers(brmData.members);
  }

  React.useEffect(() => {
    props.viewMember(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
    // const member = await viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmData?.organizationDetails[0]?._id
    //     ? brmData?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    props.getCalculatedSurvey(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      brmData?.organizationDetails[0]?._id
        ? brmData?.organizationDetails[0]?._id
        : localStorage.getItem("organization_id")
    );
    // setMembers(brmData.members);
  }, []);

  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Add New</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              {/* <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a> */}
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setAddNewUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" || email === "" || category === "" || role === ""
                }
                onClick={handleAddUser}
              >
                Add New User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const editUserDialog = () => {
    return (
      <Dialog open={editUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Edit User</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              {/* <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a> */}
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {/* <Grid item xs={12}><CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield></Grid> */}
            <Grid item xs={12} md={6} className={classes.grid4}></Grid>
            {/* <Grid item xs={12} md={6} className={classes.grid5}>
       
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={() => setEditUserDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" || email === "" || category === "" || role === ""
                }
                onClick={() => {
                  props.editMember(
                    login.token,
                    userId,
                    brmData?.organizationDetails[0]?._id
                      ? brmData?.organizationDetails[0]?._id
                      : localStorage.getItem("organization_id"),
                    role,
                    category
                  );
                  setEditUserDialogOpen(false);
                }}
              >
                Update User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At  we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });
  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid2}>
            <Grid item xs={4}>
              <Typography className={classes.Typographyshare}>
                Share Assessment
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            // style={{}}
          >
            <Grid item md={12} className={classes.grid8}>
              <Paper elevation={0} className={classes.Paper}>
                <Autocomplete
                  size="small"
                  value={emails}
                  options={
                    brmData.members && brmData.members.length > 0
                      ? brmData.members.map((mem) => mem.email)
                      : []
                  }
                  // options={maturityMatrix.notAssignedUsers.map(
                  //   (user) => user.email
                  // )}
                  // renderTags={(value, getTagProps) => {
                  //   return value.map((option, index) => (
                  //     <>
                  //       <Chip
                  //         variant="outlined"
                  //         label={option}
                  //         {...getTagProps({ index })}
                  //       />
                  //     </>
                  //   ));
                  // }}
                  onChange={(e, newValue) => {
                    // console.log('new',e.target)
                    setEmails(newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <CustomTextfield
                        {...params}
                        // onChange={(e, newValue) => {
                        //   console.log('new',newValue)
                        //   console.log('new',e.target.innerText)
                        //   // setEmails(newValue);
                        // }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Search By Emai ID or Name"
                        name="asgEmailStep3"
                      />
                    </>
                  )}
                />
              </Paper>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    // onChange={stakeHolderContentChange}
                    helperText="hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.grid8}>
                  <Typography className={classes.Typography5}>
                    Content
                  </Typography>
                </Grid>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    name="content"
                    multiline="true"
                    onChange={(e) => {
                      // stakeHolderContentChange(e);
                    }}
                    className={classes.CustomTextfield}
                    defaultValue={`hello user,  Hope this mail finds you in a good place. kindly fillout the survey form to get thr esf in relation to your company.`}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={8} md={8} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareDialogOpen(false);
                  // setEmails([]);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={4} md={4} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  // handleShareAssessment();
                  // setCheckedState(
                  //   new Array(generalSettings.allUsers.length).fill(false)
                  // );shareSurvey: (token, organization_id, email) => {

                  props.shareSurvey(
                    localStorage.getItem("user_token")
                      ? localStorage.getItem("user_token")
                      : props?.login?.token,
                    brmData?.organizationDetails[0]?._id
                      ? brmData?.organizationDetails[0]?._id
                      : localStorage.getItem("organization_id"),
                    emails && emails
                  );

                  setShareDialogOpen(false);
                }}
              >
                Send Survey
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  // useEffect(()=>{
  //   console.log('props.isEvaluator',props.isEvaluator)
  //   if(props.isEvaluator){
  //     brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 &&
  //   }
  // },[brmData.brmMainTabNo])

  const [sectors, setsectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    if (
      brmData?.organizationDetails &&
      brmData?.organizationDetails[0].sectors &&
      brmData.organizationDetails[0].sectors.length > 0
    ) {
      let masterSectors = [...brmData.sectorIndustries];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map(
                (basicInd) =>
                  masterInd._id === basicInd &&
                  (masterSectors[masterSecIdx].industry_data[
                    masterIndIdx
                  ].isSelected = true)
              )
            )
          )
      );

      setsectors(masterSectors);
    } else {
      setsectors(brmData.sectorIndustries);
    }
  }, [brmData.sectorIndustries, brmData?.organizationDetails]);

  useEffect(() => {
    return () => {
      setsectors([]);
      setOrganizationDetails("");
    };
  }, []);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    var sectorL = [...sectors];
    sectorL[sectorIndex].industry_data[industryIndex].isSelected =
      !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setsectors(sectorL);
  };
  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={() => setIsSectorDialogOpen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    key={"sector" + index}
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => (
                        <div
                          key={industry.Industry + index2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleIndustryChange(index2, index)}
                        >
                          <CustomCheckBox
                            checked={industry.isSelected}
                            name={industry.Industry}
                          />
                          <Typography id={industry.Industry}>
                            {industry.Industry}
                          </Typography>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsSectorDialogOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };
  const handleEditUser = (user) => {
    setUserId(user._id);
    setEditUserDialogOpen(true);
    setName(user.name);
    setEmail(user.email);
    setCategory(user.category);
    setRole(user.role);
  };

  return (
    <div>
      {isSectorDialogOpen && sectorIndustry()}
      {shareDialogOpen && shareDialog()}
      {addNewUserDialogOpen && addNewUserDialog()}
      {editUserDialogOpen && editUserDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
      >
        {!props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                {/* <Card
                    variant="elevation"
                    id="drawer-container"
                    elevation={1}
                    className={classes.cardContainer}
                    data-tut="reactour__basicRight"
                    >
                    <CardContent style={{ padding: 10 }}> */}
                <Typography className={classes.cardTypography}>
                  Basic Company Details
                </Typography>

                <div className="scroll" style={{ height: "61vh" }}>
                  <Grid container>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compName"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Company Name"
                          value={organizaitonDetailsObject?.name}
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Logo
                        </Typography>
                      </Box>
                      <Box my={2}>
                        {!companyLogoURI ? (
                          <>
                            <input
                              id="company-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCompanyLogo(event)
                              }
                              style={{ display: "none" }}
                              cursor="pointer"
                            />
                            <label htmlFor="company-logo">
                              <div className={classes.logoSelecter}>
                                <AddCircleOutlineIcon
                                  name="addLogoIcon"
                                  fontSize="large"
                                  className={classes.addIcon}
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.companyLogo}>
                            <img alt="company-logo" src={companyLogoURI} />
                            <IconButton
                              onClick={handleClearCompanyLogo}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "gray" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Sector & Industry
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={classes.addSectorContainer}
                    >
                      <CustomButton
                        onClick={() => {
                          setIsSectorDialogOpen(true);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Add Sector
                      </CustomButton>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        {sectors.length !== 0 ? (
                          sectors.map((sector) => (
                            <div style={{ marginBottom: 8 }}>
                              {sector.industry_data.some(
                                (inds) => inds.isSelected
                              ) && (
                                <>
                                  <Typography
                                    className={classes.selectedSectorTitle}
                                  >
                                    {sector.Sector}
                                  </Typography>
                                  {sector.industry_data.map(
                                    (industry) =>
                                      industry.isSelected && (
                                        <Chip
                                          className={classes.sectorChip}
                                          label={industry.Industry}
                                        />
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          ))
                        ) : (
                          <Chip
                            className={classes.sectorChip}
                            label="Non Selected"
                          />
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      item
                      xs={12}
                      md={11}
                    >
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User First Name"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User Last Name"
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} md={5}>
     
      </Grid> */}
                    {/* <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Designation Of The Employee"
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compOperation"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                          label="Company Operation"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              operation: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.operation}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Number of Employees"
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              no_of_employees: e.target.value,
                            }));
                          }}
                          value={organizaitonDetailsObject?.no_of_employees}
                        />
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="contactEmail"
            variant="outlined"
            fullWidth
            size="small"
            label="Contact Email"
          />
        </Box>
      </Grid> 
      <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="website"
            variant="outlined"
            fullWidth
            size="small"
            label="Website"
          />
        </Box>
      </Grid>*/}

                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Address
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]
                              ?.companyAddress
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  companyAddress: e.target.value,
                                },
                              ],
                            }));
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          variant="outlined"
                          name="compLandmark"
                          fullWidth
                          size="small"
                          label="Landmark"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.landMark
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  landMark: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.pinCode
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  pinCode: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.state
                          }
                          onChange={(e) => {
                            setOrganizaitonDetailsObject((prev) => ({
                              ...prev,
                              address: [
                                {
                                  ...prev?.address[0],
                                  state: e.target.value,
                                },
                              ],
                            }));
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="compCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.country
                              ? organizaitonDetailsObject?.address[0]?.country
                              : ""
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setOrganizaitonDetailsObject((prev) => ({
                                ...prev,
                                address: [
                                  {
                                    ...prev?.address[0],
                                    country: newValue,
                                  },
                                ],
                              }));
                            }
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Branch Office Address
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          // onClick={handleAddBranch}
                          variant="outlined"
                          color="primary"
                        >
                          Add Another Branch
                        </CustomButton>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          // value={basicInfo.branchAddress || ""}
                          // onChange={(e) => setBranchAddress(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchLandmark"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Landmark"
                          // value={basicInfo.branchLandmark || ""}
                          // onChange={(e) => setBranchLandmark(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          // value={basicInfo.branchPincode || ""}
                          // onChange={(e) => setBranchPincode(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          // value={basicInfo.branchState || ""}
                          // onChange={(e) => setBranchState(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="branchCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          // value={basicInfo.branchCountry || ""}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // setBranchCountry(newValue);
                            }
                          }}
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <CustomButton
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                        onClick={() => {
                          props.updateOrganization(
                            login.token,
                            brmData?.organizationDetails[0]?._id
                              ? brmData?.organizationDetails[0]?._id
                              : localStorage.getItem("organization_id"),
                            organizaitonDetailsObject?.name,
                            compayLogo,
                            sectors,
                            organizaitonDetailsObject?.operation,
                            organizaitonDetailsObject?.no_of_employees,
                            organizaitonDetailsObject?.address,
                            brmData.sectorIndustries
                          );
                        }}
                      >
                        Update Portfolio
                      </CustomButton>
                      <CustomButton
                        onClick={() =>
                          setOrganizaitonDetailsObject(
                            brmData?.organizationDetails[0]
                          )
                        }
                        style={{
                          float: "right",
                          textTransform: "none",
                          fontSize: "13px",
                          color: "#3374B9",
                        }}
                      >
                        Cancel
                      </CustomButton>
                    </Grid>
                    <Grid xs={7}></Grid>
                    <Grid item xs={9}>
                      {/* {LocationStore} */}
                    </Grid>
                  </Grid>
                </div>
                {/* </CardContent>
</Card> */}
              </div>
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
                      Stakeholders List
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Add />}
                      color="primary"
                      onClick={() => setAddNewUserDialogOpen(true)}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
                <div>
                  <Grid
                    container
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#EBF1F8",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid item md={5} xs={5}>
                      <Typography
                        style={{ fontSize: "13px", marginLeft: "5px" }}
                      >
                        Name / E-mail
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Typography style={{ fontSize: "13px" }}>
                        Category
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={3} align="center">
                      <Typography style={{ fontSize: "13px" }}>Role</Typography>
                    </Grid>
                    <Grid item md={1} xs={1}>
                      <Typography
                        style={{ fontSize: "13px", alignSelf: "right" }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  </Grid>
                  {brmData.members &&
                    brmData.members.length > 0 &&
                    brmData.members.map((member) => (
                      <Grid
                        container
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          marginTop: "10px",
                          padding: "5px",
                          borderBottom: "1px solid grey",
                          borderRadius: "2px",
                          alignItems: "center",
                        }}
                      >
                        <Grid item md={5} xs={5}>
                          <Typography
                            style={{ fontSize: "13px", marginLeft: "5px" }}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "12px",
                              marginLeft: "5px",
                              color: "grey",
                            }}
                          >
                            {member.email}
                          </Typography>
                        </Grid>
                        <Grid item md={3} xs={3}>
                          <Typography style={{ fontSize: "13px" }}>
                            {member.category}
                          </Typography>
                        </Grid>
                        <Grid item md={3} xs={3} align="center">
                          <Typography style={{ fontSize: "13px" }}>
                            {member.role}
                          </Typography>
                        </Grid>
                        <Grid item md={1} xs={1}>
                          <Icon
                            onClick={() => handleEditUser(member)}
                            style={{ alignSelf: "right", cursor:"pointer" }}
                          >
                            more_vert
                          </Icon>
                        </Grid>
                      </Grid>
                    ))}
                </div>
              </div>
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
              <SurveyQuestions
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                      Stakeholders Status
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Add />}
                      style={{ cursor: "pointer" }}
                      color="primary"
                      disabled={
                        brmData.members &&
                        brmData.members.length > 0 &&
                        brmData.members.filter(
                          (member) => member.status === "Sent"
                        ).length > 0
                      }
                      onClick={() => setShareDialogOpen(true)}
                    >
                      Share Survey
                    </Button>
                  </div>
                </div>
                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#EBF1F8",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item md={5} xs={4}>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      Name E-mail
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>
                      Category
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>Status</Typography>
                  </Grid>
                  <Grid item md={2} xs={3} align="center">
                    <Typography style={{ fontSize: "13px" }}>Role</Typography>
                  </Grid>
                  {/* <Grid item md={1} xs={1}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      Edit
                    </Typography>
                  </Grid> */}
                </Grid>
                {brmData.members &&
                  brmData.members.length > 0 &&
                  brmData.members.map((member) => (
                    <Grid
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "10px",
                        padding: "5px",
                        borderBottom: "1px solid grey",
                        borderRadius: "2px",
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={5} xs={4}>
                        <Typography
                          style={{ fontSize: "13px", marginLeft: "5px" }}
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            marginLeft: "5px",
                            color: "grey",
                          }}
                        >
                          {member.email}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography style={{ fontSize: "13px" }}>
                          {member.category}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography
                          style={{
                            diaplay: "inline-flex",
                            alighItems: "center",
                          }}
                        >
                          <Box
                            display="inline-flex"
                            alignItems="center"
                            // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                            p={1}
                            style={{
                              padding: "8px",
                            }}
                            borderRadius="8px"
                          >
                            <Box
                              borderRadius="50%"
                              height={10}
                              width={10}
                              bgcolor={
                                // "#5BB37F"
                                member.status === "Sent" && "#5BB37F"
                                  ? member.status === "Not Sent"
                                    ? "#5BB37F"
                                    : "#5BB37F"
                                  : "#9FA1AF"
                              }
                            />
                            <Box
                              style={{
                                paddingLeft: "5px",
                                fontSize: "14px",
                                color:
                                  member.status === "Sent" && "#5BB37F"
                                    ? member.status === "Not Sent"
                                      ? "#5BB37F"
                                      : "#5BB37F"
                                    : "#9FA1AF",
                              }}
                            >
                              {member.status}
                            </Box>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography style={{ fontSize: "13px" }} align="center">
                          {member.role}
                        </Typography>
                      </Grid>
                      {/* <Grid item md={1} xs={1} align="center">
                        <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
                      </Grid> */}
                    </Grid>
                  ))}
              </div>
            )}
          </CardContent>
        )}
        {props.isEvaluator && (
          <CardContent className={classes.cardContent}>
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 0 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                {/* <Card
variant="elevation"
id="drawer-container"
elevation={1}
className={classes.cardContainer}
data-tut="reactour__basicRight"
>
<CardContent style={{ padding: 10 }}> */}
                <Typography className={classes.cardTypography}>
                  Basic Company Details
                </Typography>

                <div
                  className="scroll"
                  style={{ height: "61vh" }}
                  id="compCont"
                >
                  <Grid container>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compName"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Company Name"
                          value={organizaitonDetailsObject?.name}
                          // onChange={(e) => {
                          //   setCompanyDetails((prev) => ({
                          //     ...prev,
                          //     companyName: e.target.value,
                          //   }));
                          // }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Logo
                        </Typography>
                      </Box>
                      <Box my={2}>
                        {!companyLogoURI ? (
                          <>
                            <input
                              id="company-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCompanyLogo(event)
                              }
                              style={{ display: "none" }}
                              cursor="pointer"
                            />
                            <label
                              htmlFor="company-logo"
                              className={classes.logoSelecter}
                            >
                              {/* <div className={classes.logoSelecter}> */}
                              <AddCircleOutlineIcon
                                name="addLogoIcon"
                                fontSize="large"
                                className={classes.addIcon}
                              />
                              {/* </div> */}
                            </label>
                          </>
                        ) : (
                          <div className={classes.companyLogo}>
                            <img src={companyLogoURI} alt="company-logo" />
                            {/*  <IconButton
                              onClick={handleClearCompanyLogo}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "gray" }}
                                fontSize="small"
                              /> 
                            </IconButton>*/}
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Sector & Industry
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      md={4}
                      className={classes.addSectorContainer}
                    >
                      <CustomButton
                        onClick={() => {
                          setIsSectorDialogOpen(true);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Add Sector
                      </CustomButton>
                    </Grid> */}

                    <Grid item xs={12} md={8}>
                      <Box my={2}>
                        {sectors.length !== 0 ? (
                          sectors.map((sector) => (
                            <div style={{ marginBottom: 8 }}>
                              {sector.industry_data.some(
                                (inds) => inds.isSelected
                              ) && (
                                <>
                                  <Typography
                                    className={classes.selectedSectorTitle}
                                  >
                                    {sector.Sector}
                                  </Typography>
                                  {sector.industry_data.map(
                                    (industry) =>
                                      industry.isSelected && (
                                        <Chip
                                          className={classes.sectorChip}
                                          label={industry.Industry}
                                        />
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          ))
                        ) : (
                          <Chip
                            className={classes.sectorChip}
                            label="Non Selected"
                          />
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      item
                      xs={12}
                      md={11}
                    >
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User First Name"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="User Last Name"
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} md={5}>
     
      </Grid> */}
                    {/* <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Designation Of The Employee"
                        />
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="compOperation"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                          label="Company Operation"
                          value={organizaitonDetailsObject?.operation}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <CustomTextfield
                          name="NumOfEmployee"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="text"
                          label="Number of Employees"
                          value={organizaitonDetailsObject?.no_of_employees}
                        />
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="contactEmail"
            variant="outlined"
            fullWidth
            size="small"
            label="Contact Email"
          />
        </Box>
      </Grid> 
      <Grid item xs={12} md={8}>
        <Box my={2}>
          <CustomTextfield
            name="website"
            variant="outlined"
            fullWidth
            size="small"
            label="Website"
          />
        </Box>
      </Grid>*/}

                    <Grid item xs={12} md={11}>
                      <Box my={2}>
                        <Typography className={classes.headerText}>
                          Company Address
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]
                              ?.companyAddress
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          variant="outlined"
                          name="compLandmark"
                          fullWidth
                          size="small"
                          label="Landmark"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.landMark
                          }
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.pinCode
                          }
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.state
                          }
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="compState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Country"
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.country
                          }
                        />
                        {/* <Autocomplete
                          name="compCountry"
                          id="countryCode"
                          options={countryList}
                          // getOptionLabel={(option) => option}
                          // renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          value={
                            organizaitonDetailsObject?.address &&
                            organizaitonDetailsObject?.address[0]?.country
                              ? organizaitonDetailsObject?.address[0]?.country
                              : ""
                          }
                          // onChange={(event, newValue) => {
                          //   if (newValue) {
                          //     console.log(newValue);
                          //     // setCompanyCountry(newValue);
                          //   }
                          // }}
                        /> */}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Add Peers
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          onClick={() => {
                            const copy = [...peerData];
                            copy.push(initialPeer);
                            setPeerData(copy);
                            console.log(
                              document.documentElement.scrollHeight,
                              window.innerHeight
                            );
                            window.scrollTo({
                              top: 50,
                              behavior: "smooth",
                            });
                            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Add Peers
                        </CustomButton>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      {peerData.map((peer, i) => {
                        return (
                          <Grid container spacing={2}  alignItems="center">
                            <Grid item xs={12} md={5}>
                              <CustomTextfield
                                name="Peers"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Add Peers"
                                value={peer.name}
                                onChange={(e) => {
                                  const copy = [...peerData];
                                  copy[i].name = e.target.value;
                                  setPeerData(copy);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomTextfield
                                name="NumOfEmployee"
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="text"
                                label="Domain Name"
                                onChange={(e) => {
                                  const copy = [...peerData];
                                  copy[i].domain = e.target.value;
                                  setPeerData(copy);
                                }}
                                value={peer.domain}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Icon style={{cursor:'pointer'}} onClick={()=>{
                            
                                const copy = [...peerData];
                                copy.splice(i,1)
                                console.log(copy , i  )
                                setPeerData(copy);
                              }} >remove</Icon>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <Grid item xs={12} md={11}>
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.headerText}>
                          Branch Office Address
                        </Typography>
                        <CustomButton
                          name="addBranch"
                          size="medium"
                          // onClick={handleAddBranch}
                          variant="outlined"
                          color="primary"
                        >
                          Add Another Branch
                        </CustomButton>
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Street/Location"
                          // value={basicInfo.branchAddress || ""}
                          // onChange={(e) => setBranchAddress(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RoomOutlinedIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchLandmark"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Landmark"
                          // value={basicInfo.branchLandmark || ""}
                          // onChange={(e) => setBranchLandmark(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchPincode"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Pincode"
                          type="number"
                          // value={basicInfo.branchPincode || ""}
                          // onChange={(e) => setBranchPincode(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <CustomTextfield
                          name="branchState"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="State"
                          // value={basicInfo.branchState || ""}
                          // onChange={(e) => setBranchState(e.target.value)}
                        />
                      </Box>
                      <Box my={2}>
                        <Autocomplete
                          name="branchCountry"
                          id="countryCode"
                          options={countryList}
                          getOptionLabel={(option) => option}
                          renderOption={(option) => option}
                          size="small"
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              label="Country"
                              variant="outlined"
                            />
                          )}
                          // value={basicInfo.branchCountry || ""}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // setBranchCountry(newValue);
                            }
                          }}
                        />
                      </Box>
                    </Grid> */}

                    <Grid xs={7}></Grid>
                    <Grid item xs={9}>
                      {/* {LocationStore} */}
                    </Grid>
                  </Grid>
                </div>
                {/* </CardContent>
</Card> */}
              </div>
            )}
            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 1 && (
              <div style={{ height: "63vh" }} className={classes.mainDiv}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                      Stakeholders Status
                    </Typography>
                  </div>
                  {/* <div>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<Add />}
                      color="primary"
                      onClick={() => setShareDialogOpen(true)}
                    >
                      Share Survey
                    </Button>
                  </div> */}
                </div>

                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#EBF1F8",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item md={5} xs={4}>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      Name E-mail
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>
                      Category
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <Typography style={{ fontSize: "13px" }}>Status</Typography>
                  </Grid>
                  <Grid item md={2} xs={3} align="center">
                    <Typography style={{ fontSize: "13px" }}>Role</Typography>
                  </Grid>
                  {/* <Grid item md={1} xs={1}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      Edit
                    </Typography>
                  </Grid> */}
                </Grid>
                {brmData.members &&
                  brmData.members.length > 0 &&
                  brmData.members.map((member) => (
                    <Grid
                      container
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "10px",
                        padding: "5px",
                        borderBottom: "1px solid grey",
                        borderRadius: "2px",
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={5} xs={4}>
                        <Typography
                          style={{ fontSize: "13px", marginLeft: "5px" }}
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            marginLeft: "5px",
                            color: "grey",
                          }}
                        >
                          {member.email}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography style={{ fontSize: "13px" }}>
                          {member.category}
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography
                          style={{
                            diaplay: "inline-flex",
                            alighItems: "center",
                          }}
                        >
                          <Box
                            display="inline-flex"
                            alignItems="center"
                            // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                            p={1}
                            style={{
                              padding: "8px",
                            }}
                            borderRadius="8px"
                          >
                            <Box
                              borderRadius="50%"
                              height={10}
                              width={10}
                              bgcolor={
                                member.status === "Sent" && "#5BB37F"
                                  ? member.status === "Not Sent"
                                    ? "#5BB37F"
                                    : "#5BB37F"
                                  : "#9FA1AF"
                              }
                            />
                            <Box
                              style={{
                                paddingLeft: "5px",
                                fontSize: "14px",
                                color:
                                  member.status === "Sent" && "#5BB37F"
                                    ? member.status === "Not Sent"
                                      ? "#5BB37F"
                                      : "#5BB37F"
                                    : "#9FA1AF",
                              }}
                            >
                              {member.status}
                            </Box>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2} align="center">
                        <Typography style={{ fontSize: "13px" }}>
                          {member.role}
                        </Typography>
                      </Grid>
                      {/* <Grid item md={1} xs={1} align="center">
                        <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
                      </Grid> */}
                    </Grid>
                  ))}
              </div>
            )}

            {/* old 0,2 */}
            {false &&
              brmData?.brmMainTabNo === 3 &&
              brmData?.brmSubTabNo === 0 && (
                <div style={{ height: "63vh" }} className={classes.mainDiv}>
                  <MuiThemeProvider theme={appTheme}>
                    <AppBar
                      position="static"
                      color="inherit"
                      className={classes.AppBar}
                    >
                      <Tabs
                        value={riskTabNo}
                        onChange={(e, newVal) => {
                          setRiskTabNo(newVal);
                        }}
                      >
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              ESG Disclosure
                            </span>
                          }
                        />
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              Sentiment Analysis
                            </span>
                          }
                        />
                        <Tab
                          disableRipple
                          className={classes.tab}
                          label={
                            <span className={classes.tabLabel}>
                              ESG Risk Summary
                            </span>
                          }
                        />
                      </Tabs>
                    </AppBar>
                  </MuiThemeProvider>
                  <TabPanel value={riskTabNo} index={0}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <RiskAnalysisRightTab {...props} />
                    </div>
                  </TabPanel>
                  <TabPanel value={riskTabNo} index={1}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <SentimentAnalysisRightTab {...props} />
                    </div>
                  </TabPanel>
                  <TabPanel value={riskTabNo} index={2}>
                    <div
                      className="scroll"
                      style={{ marginTop: 18, height: "60vh" }}
                    >
                      <RiskSummaryTab {...props} />
                    </div>
                  </TabPanel>
                </div>
              )}

            {brmData?.brmMainTabNo === 0 && brmData?.brmSubTabNo === 2 && (
              <SurveyQuestions
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 0 && (
              <ApiConnections {...props} />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 1 && (
              <Documents {...props} />
            )}
            {brmData?.brmMainTabNo === 1 && brmData?.brmSubTabNo === 2 && (
              <PeerDocuments {...props} />
            )}
            {brmData?.brmMainTabNo === 2 && brmData?.brmSubTabNo === 0 && (
              <ControverciesQuestions
                corporateSurveyQuestions={corporateSurveyQuestions}
              />
            )}
            {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 0 && (
              <EsgDeclaration {...props} />
            )}
            {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 1 && (
              <Controversy {...props} />
            )}
            {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 2 && (
              <PeerAnalysis {...props} />
            )}
            {brmData?.brmMainTabNo === 3 && brmData?.brmSubTabNo === 3 && (
              <EsgCompantProfile {...props} />
            )}
          </CardContent>
        )}
      </Card>
    </div>
  );
}

export default BrmDataRight;
