import { Button, Grid, Icon, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import tickGreen from "../../../images/TickGreen.png";
import refinitive from "../../../images/refinitive.png";
import sbt from "../../../images/sbt.png";
import moodys from "../../../images/moodys.png";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },
}));
function ApiConnections() {
  const classes = useStyles();
  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
          API Connections
        </Typography>
        <Icon style={{ cursor: "pointer" }}>more_vert</Icon>
        {/* <div>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    color="primary"
                  >
                    Add Question
                  </Button>
                </div> */}
      </div>
      <div className="scroll" style={{ height: "61vh", flexGrow: 1 }}>
        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "120px",
                      objectFit: "contain",
                      height: "55px",
                    }}
                    src={refinitive}
                    alt="logo"
                  />
                  <Button
                    style={{
                      backgroundColor: "#32A05F",
                      color: "#EBF6EF",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                  >
                    Connect
                  </Button>
                </div>
                <div>
                  <Typography
                    style={{
                      marginTop: 10,
                      fontWeight: 600,
                      color: "#374151",
                      fontSize: 14,
                    }}
                  >
                    Refinitiv
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#A3A8AF",
                      marginTop: "5px",
                    }}
                  >
                    The construction of a hydroelectric power station has an
                    ambiguous impact on the life of the local population.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "120px",
                      objectFit: "contain",
                      height: "55px",
                    }}
                    src={moodys}
                    alt="logo"
                  />
                  <Button
                    style={{
                      backgroundColor: "#32A05F",
                      color: "#EBF6EF",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                  >
                    Connect
                  </Button>
                </div>
                <div>
                  <Typography
                    style={{
                      marginTop: 10,
                      fontWeight: 600,
                      color: "#374151",
                      fontSize: 14,
                    }}
                  >
                    Moody’s
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#A3A8AF",
                      marginTop: "5px",
                    }}
                  >
                    The construction of a hydroelectric power station has an
                    ambiguous impact on the life of the local population.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.paper}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "120px",
                      objectFit: "contain",
                      height: "55px",
                    }}
                    src={sbt}
                    alt="logo"
                  />
                  <Button
                    style={{
                      backgroundColor: "#32A05F",
                      color: "#EBF6EF",
                      borderRadius: "8px",
                      textTransform: "none",
                    }}
                  >
                    Connect
                  </Button>
                </div>
                <div>
                  <Typography
                    style={{
                      marginTop: 10,
                      fontWeight: 600,
                      color: "#374151",
                      fontSize: 14,
                    }}
                  >
                    Science Based Targets
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#A3A8AF",
                      marginTop: "5px",
                    }}
                  >
                    The construction of a hydroelectric power station has an
                    ambiguous impact on the life of the local population.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ApiConnections;
