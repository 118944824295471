import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Radar } from "react-chartjs-2";
import { blue, lightBlue } from "@material-ui/core/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function RadarChart(props) {
  const { ChartTabNo, chartEsgData, brmData ,lab} = props;
  const bgColor = {
    id: "bgColor",
    beforeDraw: (chart, options) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 1,
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    plugins: [
      {
        beforeDraw: (chart, steps, options) => {
          const { ctx, width, height } = chart;
          ctx.fillStyle = "white";
          ctx.restore();
        },
      },
    ],
  };

  const data = {
    labels: [
      "Community",
      "Human Rights",
      "Management",
      "Product responsiblity",
      "Resource use",
      "Stakeholders",
      "Workforce",
    ],
    datasets: [
      {
        label:lab,
        // label:
        //   Array.isArray(brmData?.organizationDetails) &&
        //   brmData.organizationDetails[0].name,
        // data:[0.88, 0.99, 0.3, 0.6, 0.7,0.89,0.9],
        data: chartEsgData,
        backgroundColor: "#3374b933",
        borderColor: "#3374b9",
        borderWidth: 1,
      },
    ],
  };

  const config = {
    plugins: [bgColor],
  };

  const download = useCallback((ref) => {
    const canvas = ref.current;
    const canvasImage = canvas.toBase64Image();
    let pdf = new jsPDF();
    pdf.addImage(canvasImage, "JPEG", 20, 20, 185, 150);
    pdf.save("mychart.pdf");
  }, []);

  return (
    <div style={{ padding: "10px 5px", height: "60vh" }}>
      <Radar options={options} data={data} />
      {/* <Bar  options={options} data={data} /> */}
      {/* { ChartTabNo === 0 &&  <><GetAppIcon style={{float:'right'}} onClick={()=>{download(ref)}}/>     <Radar  ref={ref} config={config}  options={options} data={data} /></>}
    { ChartTabNo === 1 && <> <GetAppIcon style={{float:'right'}} onClick={()=>{download(refC1)}}/>    <Radar ref={refC1} options={options} data={getData(environmentObj)}  /></>}
    { ChartTabNo === 2 && <> <GetAppIcon style={{float:'right'}} onClick={()=>{download(refC2)}}/>    <Radar ref={refC2} options={options} data={getData(socialObj)} /></>}
    { ChartTabNo === 3 && <> <GetAppIcon style={{float:'right'}} onClick={()=>{download(refC3)}}/>    <Radar ref={refC3} options={options} data={getData(governanceObj)} /></>}
    { ChartTabNo === 4 && <> <GetAppIcon style={{float:'right'}} onClick={()=>{download(refC4)}}/>    <Radar ref={refC4} options={options} data={getData(businessObj)} /></>}
    { ChartTabNo === 5 && <> <GetAppIcon style={{float:'right'}} onClick={()=>{download(refC5)}}/>    <Radar ref={refC5} options={options} data={getData(humanObj)}  /> </>} */}
      {/*  <Radar options={options} data={data2} /> */}
    </div>
  );
}

export default RadarChart;
