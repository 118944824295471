import { CardContent, Paper, Typography } from "@material-ui/core";
import React from "react";
import RadarChart from "../../../UI/radarChart/radarChart";

function DimentionCharts(props) {
  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
        
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            {props?.brmData?.organizationDetails[0]?.name || 'Dimention Charts'}
          </Typography>
        </div>
      </div>
      <div className="scroll" style={{ height: "61vh" }}>
        
        <Paper >
        <RadarChart
        lab='OverAll'
          chartEsgData={[0.6333333333333333, 0.6, 0.6, 0.5, 1, 0.6, 0.6]}
          {...props}
        />
        </Paper>
        <Paper >
        <RadarChart
         lab='Environment'
          chartEsgData={[0.83, 0.2, 0.6, 0.1, 1, 0.7, 0.3]}
          {...props}
        />
        </Paper>
        <Paper >
        <RadarChart
         lab='Social'
          chartEsgData={[ 0.7, 0.3, 0.5, 0.5, 1, 0.9, 0.6]}
          {...props}
        />
        </Paper>
        <Paper >
        <RadarChart
         lab='Governance'
          chartEsgData={[ 0.7, 0.3, 0.5, 0.5, 1, 0.9, 0.6]}
          {...props}
        />
        </Paper>
      </div>
    </div>
  );
}

export default DimentionCharts;
