import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StackedBarChart from "../../UI/stackedBarChart/stackedBarChart";
import TopicWiseChart from "../../UI/topicWiseChart/topicWiseChart";
import CustomRange from "../../UI/CustomRange/CustomRange";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

function PeerAnalysis(props) {
  const { getBarChartData, getTopicWiseData, riskEvaluator } = props;

  const [state, setState] = useState(0);
  useEffect(() => {
    getBarChartData();
    getTopicWiseData();
    setState(1);
  }, []);
  const score = 40;
  const classes = {};
  // useEffect(() => {
  //   setScore(
  //     riskEvaluator?.sentimentScore &&
  //       riskEvaluator?.sentimentScore?.final_score
  //       ? Math.round(riskEvaluator?.sentimentScore?.final_score)
  //       : 0
  //   );
  // }, [riskEvaluator?.sentimentScore]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Peer Analysis
          </Typography>
        </div>
        <div>
          <InfoOutlinedIcon style={{ color: "#3374b9", marginRight: "12px" ,cursor:'pointer'}} />
          <ShareOutlinedIcon style={{ color: "#3374b9", marginRight: "5px" ,cursor:'pointer'}} />
        </div>
      </div>

      <div className="scroll" style={{ height: "59.5vh" }}>
        <div>
          <Grid
            container
            md={12}
            style={{
              display: "flex",
              marginTop: "12px",
            }}
          >
            <Grid md={6} xs={12} style={{ padding: "5px" }}>
              <Typography
                style={{
                  paddingLeft: 15,
                  fontSize: "20px",
                  color: "#333F48",
                  fontWeight: 600,
                }}
              >
                Sustainablity Score : {score?.toFixed(2)}
              </Typography>
              <div
                style={{
                  // border: "1px solid grey",
                  width: "auto",
                  // height: "85%",
                }}
              >
                <CustomRange score={score} lowerRange={25} upperRange={80} />
              </div>
              <div style={{}}>
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{}}>
                    {" "}
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: 14,
                        padding: "5px",
                      }}
                    >
                      Legend
                    </Typography>
                  </div>
                  <div
                    style={{
                      border: "1px solid grey",
                      borderRadius: "5px",
                      height: "3.75rem",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          padding: "2px 3px",
                          fontSize: "11px",
                          color: "#333F48",
                          textAlign: "center",
                        }}
                      >
                        Outliers with lower sustainability score
                      </Typography>
                    </div>

                    <div
                      style={{
                        width: "40%",
                        backgroundColor: "#8246af",
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: 400,
                          fontSize: 13,
                          color: "white",
                        }}
                      >
                        Peer Group Range
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          padding: "2px 3px",
                          fontSize: "11px",
                          color: "#333F48",
                          textAlign: "center",
                        }}
                      >
                        Outliers with higher sustainability score
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={6} style={{ padding: "5px", paddingBottom: "10px" }}>
              <div
                className="scroll"
                style={{
                  padding: "5px",
                  height: "50vh",
                  paddingBottom: "10px",
                  cursor: "auto",
                }}
              >
                {/* <Typography
                  style={{
                    paddingLeft: 15,
                    color: "#333F48",
                    fontWeight: 500,
                    fontSize: "14px",
                    marginTop: "4px",
                  }}
                >
                  Industry
                </Typography> */}
                <Typography
                  style={{
                    paddingLeft: 15,
                    fontWeight: 500,
                    marginTop: "2px",
                    fontSize: "14px",
                    color: "#333F48",
                  }}
                >
                  Based on our market insights and benchmarking tool,
                </Typography>
                <Typography
                  style={{
                    paddingLeft: 15,
                    color: "#333F48",
                    fontWeight: 500,
                    fontSize: "14px",
                    marginTop: "4px",
                  }}
                >
                  {` Your company is in the
                  ${
                    score <= 25
                      ? "first Quartile"
                      : score > 25 && score <= 50
                      ? "second quartile"
                      : score > 50 && score < 75
                      ? "third Quartile"
                      : "fourth quartile"
                  }
                  of the Peer group range and has significant scope to improve.`}
                </Typography>
                <Typography
                  style={{
                    paddingLeft: 15,
                    fontWeight: 500,
                    marginTop: "2px",
                    fontSize: "14px",
                    color: "#333F48",
                  }}
                >
                  Our curated sustainability solutions are designed to help you
                  progress your company’s sustainability goals to the next
                  level.
                </Typography>

                {[
                  "“Good sustainability practices positively influenced the stock price performance of 80% of companies and lowered the cost of capital of 90% of companies. Solid ESG practices are found to have resulted in better operational performance in 88% of companies”",
                ].map((topic) => {
                  return (
                    <Typography
                      key={topic}
                      style={{
                        paddingLeft: 15,
                        fontSize: "13px",
                        color: "#333F48",
                        marginTop: "15px",
                      }}
                    >
                      "{topic}"
                    </Typography>
                  );
                })}
                {/* {lenovo?.diagnosis?.chart_text?.map((topic) => {
                  return (
                    <Typography
                      key={topic}
                      style={{
                        paddingLeft: 15,
                        fontSize: "13px",
                        color: "#333F48",
                        marginTop: "4px",
                      }}
                    >
                      "{topic}"
                    </Typography>
                  );
                })} */}

                {/* <Typography
                  style={{
                    paddingLeft: 15,
                    fontSize: "13px",
                    color: "#333F48",
                    marginTop: "4px",
                  }}
                >
                  “Good sustainability practices positively influenced the stock
                  price performance of 80% of companies and lowered the cost of
                  capital of 90% of companies. Solid ESG practices are found to
                  have resulted in better operational performance in 88% of
                  companies”
                </Typography> */}
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid>
          <Grid>
            <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
              Peer Analysis
            </Typography>
          </Grid>
          <Grid
            md={12}
            lg={12}
            sm={12}
            component={Paper}
            style={{ padding: "5px", height: "400px" }}
          >
            {/* <Bar config={config} options={options} data={data} /> */}
            <StackedBarChart {...props} />
          </Grid>
          <Grid
            md={12}
            lg={12}
            sm={12}
            component={Paper}
            style={{ marginTop: "16px", padding: "5px" }}
          >
            <TopicWiseChart {...props} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PeerAnalysis;
