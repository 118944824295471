import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FilledInput,
  FormControl,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomChip from "../../UI/chip/chip";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import CancelIcon from "@material-ui/icons/Cancel";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import countryList from "../../../utils/countries/countryList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {  valueOrDefault } from "../../../../node_modules/chart.js/dist/helpers.mjs";
import { Bar, Radar } from "react-chartjs-2";
import RadarChart from "../../UI/radarChart/radarChart";
import {
  createOrganization,
  viewOrganization,
} from "../../../redux/actions/organization/organizationAction";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
function RiskAssessment(props) {
  const useStyles = makeStyles(() => ({
    mainDiv: {
      padding: "5px 20px",
      minWidth: 700,
      maxWidth: 700,
      overflowY: "hidden",
    },
    headerText: {
      fontWeight: "600",
    },
    cardTypography: {
      fontWeight: "600",
    },
    logoSelecter: {
      border: "2px dashed #DADBE6",
      width: 120,
      height: 120,
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        "& $addIcon": {
          color: "#3374B9",
        },
      },
    },
    addIcon: {
      color: "#DADBE6",
    },
    companyLogo: {
      border: "2px solid #DADBE6",
      width: 120,
      height: 120,
      borderRadius: 5,
      zIndex: 1,
      "& img": {
        width: "100%",
        height: "100%",
      },
      position: "relative",
    },
    ClearImage: {
      position: "absolute",
      right: 0,
      top: 0,
      padding: 2,
    },
    addSectorContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    },
    sectorChip: {
      justifyContent: "flex-start",
      color: "#77787B",
      backgroundColor: "#F5F6F8",
      borderRadius: "5%",
      border: "0px",
      margin: "4px 8px 4px 0px",
      "&&:hover": {
        "& $removeChipIcon": {
          display: "inline",
        },
      },
      "&&:focus": {
        color: "#77787B",
        backgroundColor: "#F5F6F8",
      },
    },
    selectedSectorTitle: {
      color: "#77787B",
      fontSize: 14,
    },
    removeChipIcon: {
      display: "none",
    },
    tableHeader: {
      marginTop: "10px",
      backgroundColor: "#EBF1F8",
      padding: "0px 5px",
      borderRadius: "8px",
      height: "15px",
    },
    tableData: {
      padding: "6px",
    },
    tableheadData: {
      padding: "0px 5px",
    },
    statusOrange: {
      padding: "3px",
      borderRadius: "50%",
      backgroundColor: "orange",
      width: "8px",
      height: "8px",
    },
    downloadButton: {
      textTransform: "none",
      float: "right",
      marginRight: "10px",
      color: "#3374B9",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
        boxShadow: "1px 1px 3px #c8c0c0a1",
      },
    },
    createButton: {
      textTransform: "none",
      float: "right",
      marginRight: "20px",
      color: "#fff",
      backgroundColor: "#3374B9",
      "&:hover": {
        backgroundColor: "#3374B9",
        boxShadow: "1px 1px 3px #c8c0c0a1",
      },
    },
    searchCont: {
      marginRight: 10,
    },
    headerIcon: { color: "#fff", paddingLeft: "5px" },
  }));
  const classes = useStyles();
  const history = useHistory();
  const {
    generalSettings,
    viewDetailsOrganization,
    login,
    brmData,
    set_snack_bar,
  } = props;
  useEffect(() => {
    // props.getMaturityMatrix(login.token);
    // props.getAssessmentDetails(login.token, "63d35909c19f5ec6cf6a6184");
    // props.viewAllUsers(login.token, login.current_organisation, "");
  }, []);

  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    address: [{ landmark: "", state: "" }],
  });

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const datas = [
    {
      name: "compant name",
      date: "12/3/2023",
      userName: "userName",
      status: "in progress",
    },
  ];
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [sectors, setSectors] = useState([]);

  const [isAddcompanyDrawer, setIsAddCompanyDrawer] = useState("");

  const [isHover, setHover] = useState(false)

  const [compayLogo, setCompanyLogo] = useState("");
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const addCompanyDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={isAddcompanyDrawer}
        onClose={handleAddCompanyDialogClose}
      >
        <div className={classes.mainDiv}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              {/* <Typography
                style={{ fontWeight: "bold", fontSize: "18px" }}
                variant="body1"
              >
                Stakeholders
              </Typography> */}
            </Grid>
            <Grid></Grid>
            <Grid item>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsAddCompanyDrawer(false);
                }}
              />
            </Grid>
          </Grid>
          {/* <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicRight"
      >
        <CardContent style={{ padding: 10 }}> */}
          <Typography className={classes.cardTypography}>
            Basic Company Details
          </Typography>

          <div className="scroll" style={{ marginTop: 18, height: "86vh" }}>
            <Grid container>
              <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="compName"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Company Name"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        companyName: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Company Logo
                  </Typography>
                </Box>
                <Box my={2}>
                  {!companyLogoURI ? (
                    <>
                      <input
                        id="company-logo"
                        type="file"
                        onChange={(event) => handleUploadCompanyLogo(event)}
                        style={{ display: "none" }}
                        cursor="pointer"
                      />
                      <label htmlFor="company-logo">
                        <div className={classes.logoSelecter}>
                          <AddCircleOutlineIcon
                            name="addLogoIcon"
                            fontSize="large"
                            className={classes.addIcon}
                          />
                        </div>
                      </label>
                    </>
                  ) : (
                    <div className={classes.companyLogo}>
                      <img src={companyLogoURI} alt="company-logo" />
                      <IconButton
                        onClick={handleClearCompanyLogo}
                        className={classes.ClearImage}
                      >
                        <CancelIcon
                          style={{ color: "gray" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Sector & Industry
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.addSectorContainer}>
                <CustomButton
                  onClick={() => {
                    setIsSectorDialogOpen(true);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Add Sector
                </CustomButton>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box my={2}>
                  {sectors.length !== 0 ? (
                    sectors.map((sector) => (
                      <div style={{ marginBottom: 8 }}>
                        {sector.industry_data.some(
                          (inds) => inds.isSelected
                        ) && (
                          <>
                            <Typography className={classes.selectedSectorTitle}>
                              {sector.Sector}
                            </Typography>
                            {sector.industry_data.map(
                              (industry) =>
                                industry.isSelected && (
                                  <Chip
                                    className={classes.sectorChip}
                                    label={industry.Industry}
                                  />
                                )
                            )}
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <Chip className={classes.sectorChip} label="Non Selected" />
                  )}
                </Box>
              </Grid>
              {/* <Grid
                container
                justifyContent="space-between"
                spacing={1}
                item
                xs={12}
                md={11}
              >
                <Grid item md={6}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="User First Name"
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="User Last Name"
                  />
                </Grid>
              </Grid> */}
              {/* <Grid item xs={12} md={5}>
             
              </Grid> */}
              {/* <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Designation Of The Employee"
                  />
                </Box>
              </Grid> */}
              <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="compOperation"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    size="small"
                    label="Company Operation"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        operation: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="NumOfEmployee"
                    variant="outlined"
                    fullWidth
                    size="small"
                    type="text"
                    label="Number of Employees"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        no_of_employees: e.target.value,
                      }));
                    }}
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={8}>
                <Box my={2}>
                  <CustomTextfield
                    name="contactEmail"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Contact Email"
                  />
                </Box>
              </Grid>  */}
              <Grid item xs={12} md={11}>
                <Box my={2}>
                  <CustomTextfield
                    name="website"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Website"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={11}>
                <Box my={2}>
                  <Typography className={classes.headerText}>
                    Company Address
                  </Typography>
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compAddress"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Street/Location"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomOutlinedIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            companyAddress: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    variant="outlined"
                    name="compLandmark"
                    fullWidth
                    size="small"
                    label="Landmark"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            landMark: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compPincode"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Pincode"
                    type="number"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            pinCode: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="compState"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="State"
                    onChange={(e) => {
                      setCompanyDetails((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev?.address[0],
                            state: e.target.value,
                          },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    name="compCountry"
                    id="countryCode"
                    options={countryList}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    size="small"
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // setCompanyCountry(newValue);

                        setCompanyDetails((prev) => ({
                          ...prev,
                          address: [
                            {
                              ...prev?.address[0],
                              country: newValue,
                            },
                          ],
                        }));
                      }
                    }}
                  />
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={11}>
                <Box
                  my={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography className={classes.headerText}>
                    Branch Office Address
                  </Typography>
                  <CustomButton
                    name="addBranch"
                    size="medium"
                    onClick={handleAddBranch}
                    variant="outlined"
                    color="primary"
                  >
                    Add Another Branch
                  </CustomButton>
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchAddress"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Street/Location"
                    // value={basicInfo.branchAddress || ""}
                    // onChange={(e) => setBranchAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RoomOutlinedIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchLandmark"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Landmark"
                    // value={basicInfo.branchLandmark || ""}
                    // onChange={(e) => setBranchLandmark(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchPincode"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Pincode"
                    type="number"
                    // value={basicInfo.branchPincode || ""}
                    // onChange={(e) => setBranchPincode(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchState"
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="State"
                    // value={basicInfo.branchState || ""}
                    // onChange={(e) => setBranchState(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    name="branchCountry"
                    id="countryCode"
                    options={countryList}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    size="small"
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                    // value={basicInfo.branchCountry || ""}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // setBranchCountry(newValue);
                      }
                    }}
                  />
                </Box>
              </Grid> */}
              <Grid item xs={12} md={12}>
                <CustomButton
                  className={classes.downloadButton}
                  style={{
                    float: "right",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                >
                  Create Portfolio & add Another
                </CustomButton>
                <CustomButton
                  className={classes.downloadButton}
                  style={{
                    float: "right",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  onClick={handleCreateOrganization}
                >
                  Create Portfolio
                </CustomButton>
                <CustomButton
                  className={classes.downloadButton}
                  style={{
                    float: "right",
                    textTransform: "none",
                    fontSize: "13px",
                  }}
                  onClick={handleAddCompanyDialogClose}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid xs={7}></Grid>
              <Grid item xs={9}>
                {/* {LocationStore} */}
              </Grid>
            </Grid>
          </div>
          {/* </CardContent>
      </Card> */}
        </div>
      </Drawer>
    );
  };

  useEffect(() => {
    if (
      brmData.organizationDetails &&
      brmData?.organizationDetails[0].sectors &&
      brmData.organizationDetails[0].sectors.length > 0
    ) {
      let masterSectors = [...brmData.sectorIndustries];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          brmData.organizationDetails[0].sectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            brmData.organizationDetails[0].sectors.map((basicSec) =>
              basicSec.industry.map(
                (basicInd) =>
                  masterInd._id === basicInd &&
                  (masterSectors[masterSecIdx].industry_data[
                    masterIndIdx
                  ].isSelected = true)
              )
            )
          )
      );
      setSectors(masterSectors);
    } else {
      setSectors(brmData.sectorIndustries);
    }
  }, [brmData.sectorIndustries, isAddcompanyDrawer]);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    var sectorL = [...sectors];
    sectorL[sectorIndex].industry_data[industryIndex].isSelected =
      !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setSectors(sectorL);
  };

  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={() => setIsSectorDialogOpen(false)}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                    name={sector.Sector}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        id={sector.Sector}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleIndustryChange(index2, index)}
                        >
                          <CustomCheckBox
                            checked={industry.isSelected}
                            name={industry.Industry}
                          />
                          <Typography id={industry.Industry}>
                            {industry.Industry}
                          </Typography>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsSectorDialogOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid2}>
            <Grid item xs={4}>
              <Typography className={classes.Typography3}>
                Share Report
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            // style={{}}
          >
            <Grid item md={12} className={classes.grid8}>
              <Paper elevation={0} className={classes.Paper}>
                <Autocomplete
                  size="small"
                  multiple
                  // value={emails}
                  options={
                    generalSettings.allUsers &&
                    generalSettings?.allUsers.map((user) => user.email)
                  }
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <>
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      </>
                    ));
                  }}
                  onChange={(event, newValue) => {
                    // setEmails(newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <CustomTextfield
                        {...params}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Search By Emai ID or Name"
                        name="asgEmailStep3"
                      />
                    </>
                  )}
                />
              </Paper>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    // onChange={stakeHolderContentChange}
                    helperText="hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.grid8}>
                  <Typography className={classes.Typography5}>
                    Description
                  </Typography>
                </Grid>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    name="content"
                    multiline="true"
                    onChange={(e) => {
                      // stakeHolderContentChange(e);
                    }}
                    placeholder="Describe the Report"
                    className={classes.CustomTextfield}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={8} md={8} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareDialogOpen(false);
                  // setEmails([]);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={4} md={4} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  history.push("/corporate_questions");
                  setShareDialogOpen(false);
                }}
              >
                Share Report
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClearCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoURI("");
  };

  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    return () => {
      setSectors([]);
    };
  }, []);
  const handleAddCompanyDialogClose = () => {
    setSectors(brmData.sectorIndustries);
    setIsAddCompanyDrawer(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const NUMBER_CFG = { count: 4, min: 1, max: 20 };
  var _seed = Date.now();
  const rand = (min, max) => {
    min = valueOrDefault(min, 0);
    max = valueOrDefault(max, 0);
    _seed = (_seed * 9301 + 49297) % 233280;
    return min + (_seed / 233280) * (max - min);
  };
  function numbers(config) {
    var cfg = config || {};
    var min = valueOrDefault(cfg.min, 0);
    var max = valueOrDefault(cfg.max, 100);
    var from = valueOrDefault(cfg.from, []);
    var count = valueOrDefault(cfg.count, 8);
    var decimals = valueOrDefault(cfg.decimals, 8);
    var continuity = valueOrDefault(cfg.continuity, 1);
    var dfactor = Math.pow(10, decimals) || 0;
    var data = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = (from[i] || 0) + rand(min, max);
      if (rand() <= continuity) {
        data.push(Math.round(dfactor * value) / dfactor);
      } else {
        data.push(null);
      }
    }
    return data;
  }

  const CHART_COLORS = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };
  const labels = ["a", "b", "c", "d"];

  const options = {
    maintainAspectRatio: false,
    // aspectRatio: 1
    scales: {
      // r: {
      //   suggestedMin: 0,
      //   suggestedMax: 1,
      // },
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      // tooltip: false,
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
      beforeDraw: (chart, steps, options) => {
        const { ctx, width, height } = chart;
        ctx.fillStyle = "white";
        ctx.restore();
      },
    },
    elements: {
      arc: {
        backgroundColor: "white",
        hoverBackgroundColor: "25px",
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Environment",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.red,
        stack: "Stack 0",
      },
      {
        label: "Social ",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.blue,
        stack: "Stack 0",
      },
      {
        label: "Dataset 3",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.green,
        stack: "Stack 0",
      },
      {
        label: "Dataset 4",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.yellow,
        stack: "Stack 0",
      },
      {
        label: "Dataset 5",
        data: numbers(NUMBER_CFG),
        backgroundColor: CHART_COLORS.orange,
        stack: "Stack 0",
      },
    ],
  };
  const config = {
    type: "bar",
    data: data,
    options: {
      plugins: {
        title: {
          display: true,
          text: "Chart.js Bar Chart - Stacked",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        // y: {
        //   stacked: true
        // }
      },
    },
  };
  // const data = {
  //   labels: [
  //     "Workforce",
  //     "Community",
  //     "Human Rights",
  //     "Management",
  //     "Product responsiblity",
  //     "Resource use",
  //     "Stakeholders",
  //   ],
  //   datasets: [
  //     {
  //       label: "Company1",
  //       data: [0.88, 0.99, 0.3, 0.6, 0.7, 0.89, 0.9],
  //       // data: chartEsgData,
  //       backgroundColor: "#3374b933",
  //       borderColor: "#3374b9",
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // handle Create organization API

  function handleCreateOrganization() {
    props.createOrganization(
      login?.token,
      login.bankId,
      companyDetails?.companyName,
      compayLogo,
      sectors,
      companyDetails?.operation,
      companyDetails?.no_of_employees,
      companyDetails?.address,
      brmData.sectorIndustries
    );
    setIsAddCompanyDrawer(false);
    // setCompaniesData(viewOrganizations.result);
  }

  const viewCompanies = (toMap) => {
    return toMap
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, i) => (
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #EBF1F8",
            borderRadius: 5,
            padding: "5px 0px 5px 5px",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: isHover[row?._id] ? "#EBF1F8" : "#FEFEFE"
          }}
          onClick={() => handleViewDetailsOrganization(row?._id)}
          onMouseEnter={() => setHover(preValue => ({...preValue, [row?._id] : true}))}
          onMouseLeave={() => setHover(preValue => ({...preValue, [row?._id] : false}))}

        >
          <div style={{ width: "40%", display:"flex"   }}>
            <Icon
              style={{ cursor: "pointer", color:"#15314E" }}
              onClick={() => handleViewDetailsOrganization(row._id)}
              fontSize="small"
            >
              descriptionicon
            </Icon>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                padding: "0px 5px",
              }}
            >
              {row.name}
            </Typography>
          </div>
          <div style={{ width: "20%" }} align="center">
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                padding: "0px 5px",
              }}
            >
              {row?.lastModified ? row?.lastModified : "N/A"}
            </Typography>
          </div>
          <div style={{ width: "20%" }} align="center">
            <Typography
              style={{ diaplay: "inline-flex", alighItems: "center" }}
            >
              <Box
                display="inline-flex"
                alignItems="center"
                // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                p={1}
                style={{
                  padding: "8px",
                }}
                borderRadius="8px"
              >
                <Box
                  borderRadius="50%"
                  height={10}
                  width={10}
                  bgcolor={
                    // "#5BB37F"
                    row.status === "Completed"
                      ? "#5BB37F"
                      : row.status === "Not Completed"
                      ? "#FFC250"
                      : "#9FA1AF"
                  }
                />
                <Box
                  style={{
                    paddingLeft: "5px",
                    fontSize: "14px",
                    color:
                      row.status === "Completed"
                        ? "#5BB37F"
                        : row.status === "Not Completed"
                        ? "inherit"
                        : "#5BB37F",
                  }}
                >
                  {row.status}
                </Box>
              </Box>
            </Typography>
          </div>
          <div style={{ width: "20%" }} align="center">
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                padding: "0px 5px",
              }}
            >
              {row.country ? row.country : "N/A"}
            </Typography>
          </div>
          <div style={{ width: "10%" }} align="center">
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                padding: "0px 5px",
              }}
            >
              <Icon
                style={{ cursor: "pointer" }}
                onClick={() => handleViewDetailsOrganization(row._id)}
                fontSize="small"
              >
                more_vert
              </Icon>
            </Typography>
          </div>
        </div>
      ));
  };

  React.useEffect(() => {
    props.viewOrganization(
      login?.token ? login?.token : localStorage.getItem("user_token"),
      login.bankId ? login.bankId : localStorage.getItem("bank_id")
    );
    props?.getSectorIndustries(
      login?.token ? login?.token : localStorage.getItem("user_token")
    );
  }, []);

  function handleViewDetailsOrganization(id) {
    // console.log("data", id,viewDetailsOrganization)
    props.viewDetailsOrganization(props.login?.token, id);
    localStorage.setItem("organization_id", id);
    props.isEvaluator
      ? props.history.push("/risk_evaluator/company_details", {
          data: props.brmData.organizationDetails,
          organization: id,
        })
      : props.history.push("/risk_analysis/company_details", {
          data: props.brmData.organizationDetails,
          organization: id,
        });
  }
  const [companies, setCompanies] = useState([]);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  useEffect(() => {
    brmData?.organizationsList &&
      brmData?.organizationsList.length > 0 &&
      brmData?.organizationsList.filter((comp) => {
        return comp.name === searchCompanyText;
      });
    brmData?.organizationsList &&
    brmData?.organizationsList.length > 0 &&
    searchCompanyText
      ? setCompanies(
          brmData?.organizationsList.filter((comp) => {
            return comp.name
              .toLowerCase()
              .includes(searchCompanyText.toLowerCase());
          })
        )
      : setCompanies([]);
  }, [searchCompanyText]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const handleChangePage = (event, newPage) => {
    // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // console.log("new ", page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {isSectorDialogOpen && sectorIndustry(login, sectors)}
      {isAddcompanyDrawer && addCompanyDrawer()}
      {shareDialogOpen && shareDialog()}
      <div>
        {/* un comment the condition to make it only for rm */}
        {/* {!props.isEvaluator && ( */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{ fontWeight: 600, fontSize: "16px", marginRight: "auto" }}
          >
            Portfolio
          </Typography>
          <div style={{ width: "30%", marginRight: 15 }}>
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={(e) => {
                setSearchCompanyText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchCompanyText}
            />
          </div>
          <div>
            <Button
              onClick={() => {
                setIsAddCompanyDrawer(true);
              }}
              className={classes.createButton}
              size="small"
            >
              <AddIcon
                className={classes.headerIcon}
                color="primary"
                variant="outlined"
              />
              Create Company
            </Button>
          </div>
          <Pagination
            style={{ paddingRight: 10 }}
            count={Math.round(
              brmData?.organizationsList &&
                brmData?.organizationsList.length / rowsPerPage
            )}
            siblingCount={0}
            defaultPage={1}
            onChange={handleChangePage}
            variant="outlined"
            color="primary"
            size="small"
          />
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={
              brmData?.organizationsList && brmData?.organizationsList.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          {/* <Button size="small" className={classes.downloadButton}>
            Download Template
          </Button> */}
        </div>
        {/* )} */}
        {/* <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            backgroundColor: "#EBF1F8",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              Company Name
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography style={{ fontSize: "13px" }}>Survey Status</Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography style={{ fontSize: "13px" }}>
              Contact Position
            </Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography style={{ fontSize: "13px" }}>Actions</Typography>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          justifyContent="flex-start"
          style={{ marginLeft: "1rem" }}
        >
          <Grid
            item
            xs={10}
            style={{ marginTop: 40 }}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: props.open,
            })}
          >
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: "0px" }}>
                      <List>
                        <ListItem>
                          <Typography variant="h6" style={{ fontSize: 16 }}>
                            Company details
                          </Typography>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px", cursor: "pointer" }}
                      // onClick={handleSorting}
                    >
                      <List>
                        <ListItem>
                          <Typography
                            variant="h6"
                            style={{ fontSize: 16, paddingRight: "8px" }}
                          >
                            Last Modified
                          </Typography>
                          {/* {showAscDecIcon ? (
                          <ArrowUpwardIcon
                            style={{
                              fontSize: "16px",
                              color: "#000000",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            style={{
                              fontSize: "16px",
                              color: "#000000",
                            }}
                          />
                        )} 
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        Edited by
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {datas.map((s, index) => {
                    return (
                      <TableRow key={index} className={classes.assignment_row}>
                        <TableCell style={{ padding: "0px" }}>
                          <Link
                            to={{
                              pathname: "/risk_analysis/company_details",
                              state: {
                                assessment_id: s.date,
                                assessment_name: s.name,
                              },
                            }}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <ListItem>
                              <Icon fontSize="small">feed</Icon>
                              <Typography
                                variant="h6"
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  fontSize: 14,
                                }}
                              >
                                {s.name}
                              </Typography>
                            </ListItem>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={{
                              pathname: "/risk_analysis/company_details",
                              state: { assessment_id: s.name },
                            }}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            {s.date}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={{
                              pathname: "/risk_analysis/company_details",
                              state: { assessment_id: s.date },
                            }}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            {s.date}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={{
                              pathname: "/risk_analysis/company_details",
                              state: { assessment_id: s.date },
                            }}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            <CustomChip
                              variant="outlined"
                              label={s.status ? "Completed" : "In Progress"}
                              color={!s.status ? "secondary" : "primary"}
                              avatar={
                                <Avatar
                                  style={{
                                    color: !s.status ? "#FFC250" : "#32A05F",
                                    backgroundColor: !s.status
                                      ? "#FFC250"
                                      : "#32A05F",
                                    height: "12px",
                                    width: "12px",
                                    marginLeft: "10px",
                                  }}
                                ></Avatar>
                              }
                            />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid> */}
      </div>
      <div>
        {/* <Grid>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Portfolio 65465456
          </Typography>
        </Grid> */}
        {/* <CustomButton
          style={{ width: "95%", marginTop: "15px", backgroundColor: "grey" }}
          onClick={() => {
            setCompaniesData((prev) => {
              prev.push(companyDetails);
              return [...prev];
            });
          }}
        >
          Add Company
        </CustomButton> */}
        {/* <TableContainer style={{ padding: "10px 60px", marginTop: "25px" }}>
          <Table style={{ width: "100%" }}>
            <TableHead style={{padding:0}}>
              <TableRow className={classes.tableHeader}>
                <TableCell  className={classes.tableheadData}>
      
                  <Typography style={{fontSize:'14px',fontWeight :500}}> Name Of The Company</Typography>
                </TableCell>
                <TableCell className={classes.tableheadData} align="right">
                  <Typography> Survey Status</Typography>
                </TableCell>
                <TableCell className={classes.tableheadData} align="right">
                  <Typography> country</Typography>
                </TableCell>
                <TableCell className={classes.tableheadData} align="right">
                  <Typography> Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brmData?.organizationsList &&
                brmData?.organizationsList.length > 0 &&
                brmData?.organizationsList.map((row, i) => (
                  <TableRow
                    onClick={() => handleViewDetailsOrganization(row._id)}
                    key={row.name + i}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      className={classes.tableData}
                      // component="th"
                      // scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableData} align="right">
                      <Typography
                        style={{ diaplay: "inline-flex", alighItems: "center" }}
                      >
                        <Box
                          display="inline-flex"
                          alignItems="center"
                          // bgcolor={true ? '#EBF6EF' : '#E9E9E9'}
                          p={1}
                          style={{
                            padding: "8px",
                          }}
                          borderRadius="8px"
                        >
                          <Box
                            borderRadius="50%"
                            height={10}
                            width={10}
                            bgcolor={
                              // "#5BB37F"
                              row.status === "Completed"
                                ? "#5BB37F"
                                : row.status === "Not Completed"
                                ? "#FFC250"
                                : "#9FA1AF"
                            }
                          />
                          <Box
                            style={{
                              paddingLeft: "5px",
                              fontSize: "14px",
                              color:
                                row.status === "Completed"
                                  ? "#5BB37F"
                                  : row.status === "Not Completed"
                                  ? "inherit"
                                  : "#5BB37F",
                            }}
                          >
                            {row.status}
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableData} align="right">
                      {row.country ? row.country : "NA"}
                    </TableCell>
                    <TableCell className={classes.tableData} align="right">
                      <CustomButton
                        onClick={() => handleViewDetailsOrganization(row._id)}
                        // onClick={() => setShareDialogOpen(true)}
                        style={{ backgroundColor: "white" }}
                      >
                        <Icon fontSize="small">more_vert</Icon>
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer> */}

        <Grid style={{ padding: "10px 60px", marginTop: "25px" }}>
          <div
            style={{
              display: "flex",
              borderRadius: 5,
              backgroundColor: "#EBF1F8",
            }}
          >
            <div style={{ width: "40%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "0px 5px",
                }}
              >
                Name Of The Company
              </Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography> Last Modified</Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography> Survey Status</Typography>
            </div>
            <div style={{ width: "20%" }} align="center">
              <Typography> country</Typography>
            </div>
            <div style={{ width: "10%" }} align="center">
              <Typography> Actions</Typography>
            </div>
          </div>

       <div style={{marginTop:'15px'}}>   {companies.length > 0 ? (
            searchCompanyText ? (
              viewCompanies(companies)
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "25px" }}>
                No Data Found.
              </Typography>
            )
          ) : !searchCompanyText &&
            brmData?.organizationsList &&
            brmData?.organizationsList.length > 0 ? (
            viewCompanies(brmData?.organizationsList)
          ) : (
            <Typography style={{ textAlign: "center", marginTop: "25px" }}>
              No Companies Added.
            </Typography>
          )}</div>

          {/* {Array.isArray(brmData?.organizationsList) &&
          brmData?.organizationsList.lenth > 0 &&
          searchCompanyText !== "" ? (
            viewCompanies(brmData?.organizationsList)
          ) : companies.length > 0  ? (
            viewCompanies(companies)
          ) : (
            <Typography style={{ textAlign: "center", marginTop: "25px" }}>
              No Data Found.
            </Typography>
          )} */}
        </Grid>
      </div>
    </>
  );
}

export default RiskAssessment;
