import {
  FormControl,
  Grid,
  Icon,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import EsgRiskSummary from "./EsgRiskSummary";

function RiskSummaryTab(props) {
  const useStyles = makeStyles(() => ({
    topicStyle: {
      width: "40px",
      height: "40px",
      marginTop: "5px",
      position: "relative",
      top: "5px",
    },
    mainGrid: {
      textAlign: "center",
    },
    scoreImage: {
      width: "180px",
      height: "160px",
    },
    maxScoreTypo: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
    },
    scoreTypo: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "xx-large",
    },
    sharesurvey: {
      color: "#3374B9",
      fontWeight: 600,
    },
    describe: {
      color: "grey",
      fontSize: "x-small",
      marginTop: "11px",
    },
    Table: {
      outline: "0.5px solid lightGrey",
      borderRadius: "5px",
      boxShadow: "1px 1px 1px",
      height: "100%",
    },
    TableContainer: {
      padding: "3px",
    },
    TableCell: {
      padding: "5px",
      border: "none",
    },
    hedaderCell: { color: "grey", fontSize: "x-small" },
    topicCell: { padding: "4px 4px" },
    lableCell: {
      // width: '193px',
      // height: '20px',
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      // lineHeight: "20px",
    },
    scoreCell: {
      textAlign: "right",
      // width: '32px',
      // height: '28px',
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "28px",
    },
    cardContainer: {
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
      // height:'100%'
    },
    cardContent: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
    },
    LinearProgress: {
      // color:'success.main',
      margin: "-25px 10px 25px 10px",
    },
    progressIndicator1: {
      position: "relative",
      left: "-18%",
      top: "12px",
      backgroundColor: "#3374B9",
      zIndex: 1,
    },
    progressIndicator2: {
      position: "relative",
      left: "17.7%",
      top: "12px",
      backgroundColor: "#3374B9",
      zIndex: 1,
    },
    upColor: {
      border: "none",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      marginRight: "5px",
      backgroundColor: "green",
    },
    flatColor: {
      border: "none",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      marginRight: "5px",
      backgroundColor: "yellow",
    },
    downColor: {
      border: "none",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      marginRight: "5px",
      backgroundColor: "#C45446",
    },
    arrowUp: { width: "20px", height: "20px", color: "#fff" },
  }));

  const classes = useStyles();
  const final_categories_arr = [
    "Community",
    "Human rights",
    "Management",
    "Product responsibility",
    "Resource use",
    "Shareholders",
    "Workforce",
  ];

  const categoryWiseScore = final_categories_arr.map(
    (category, categoryIndx) => {
      const scoreObj = {
        lowScore: { scroreString: "Low", scoreNUmber: 0 },
        mediumScore: { scroreString: "Medium", scoreNUmber: 1 },
        highScore: { scroreString: "High", scoreNUmber: 2 },
      };
      const { lowScore, mediumScore, highScore } = scoreObj;
      let categoryDisclosureScoreNumber =
        props.riskEvaluator?.calculatedEsgDisclosure?.final_categories &&
        props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
          category
        ];
      let categorySentimentScoreNumber =
        props.riskEvaluator?.sentimentScore?.category_values &&
        props.riskEvaluator?.sentimentScore?.category_values[category];
      let categoryDisclosureScore =
        categoryDisclosureScoreNumber < 6.7
          ? lowScore
          : categoryDisclosureScoreNumber < 13.5
          ? mediumScore
          : categoryDisclosureScoreNumber > 13.5
          ? highScore
          : lowScore;
      let categorySentimentScore =
        categorySentimentScoreNumber < 6.7
          ? lowScore
          : categorySentimentScoreNumber < 13.5
          ? mediumScore
          : categorySentimentScoreNumber > 13.5
          ? highScore
          : lowScore;

      let deviationScore =
        categoryDisclosureScore.scoreNUmber >=
        categorySentimentScore.scoreNUmber
          ? "Positive"
          : "Negative";

      return {
        slNo: categoryIndx + 1,
        topic: category,
        EsgScore: categoryDisclosureScore.scroreString,
        sentimentScore: categorySentimentScore.scroreString,
        deviation: deviationScore,
      };
    }
  );

  // const rows = [
  //   {
  //     slNo: 0 + 1,
  //     topic: "Community",
  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Community"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Community"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Community"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Community"
  //           ] > 13.5 && "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Community"] <
  //         6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Community"] <
  //             13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values["Community"] >
  //             6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Community"] >
  //             13.5 && "High"
  //       : "low",
  //     deviation: "Negative",
  //   },
  //   {
  //     slNo: 1 + 1,
  //     topic: "Human Rights",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Human rights"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Human rights"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Human rights"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Human rights"
  //           ] > 13.5 && "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Human rights"] <
  //         6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Human rights"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values[
  //             "Human rights"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Human rights"
  //           ] > 13.5 && "High"
  //       : "low",
  //     deviation: "Positive",
  //   },
  //   {
  //     slNo: 2 + 1,
  //     topic: "Management",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Management"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Management"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Management"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Management"
  //           ] > 13.5 && "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Management"] <
  //         6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Management"] <
  //             13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values["Management"] >
  //             6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Management"] >
  //             13.5 && "High"
  //       : "low",
  //     deviation: "Positive",
  //   },
  //   {
  //     slNo: 3 + 1,
  //     topic: "Product Responsiblity",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Product responsibility"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Product responsibility"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Product responsibility"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Product responsibility"
  //           ] > 13.5 && "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values[
  //           "Product responsibility"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Product responsibility"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values[
  //             "Product responsibility"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Product responsibility"
  //           ] > 13.5 && "High"
  //       : "low",
  //     deviation: "Positive",
  //   },
  //   {
  //     slNo: 4 + 1,
  //     topic: "Resource Use",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Resource use"
  //         ] > 0 &&
  //         props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Resource use"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Resource use"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Resource use"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Resource use"
  //           ] > 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Resource use"
  //           ] < 21 &&
  //           "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Resource use"] >
  //           0 &&
  //         props.riskEvaluator?.sentimentScore?.category_values["Resource use"] <
  //           6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Resource use"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values[
  //             "Resource use"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Resource use"
  //           ] > 13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values[
  //             "Resource use"
  //           ] < 21 &&
  //           "High"
  //       : "low",
  //     deviation: "Negative",
  //   },
  //   {
  //     slNo: 5 + 1,
  //     topic: "Shareholders",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Shareholders"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Shareholders"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Shareholders"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Shareholders"
  //           ] > 13.5 && "High"
  //       : "low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Shareholders"] <
  //         6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Shareholders"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values[
  //             "Shareholders"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values[
  //             "Shareholders"
  //           ] > 13.5 && "High"
  //       : "low",
  //     deviation: "Negative",
  //   },
  //   {
  //     slNo: 6 + 1,
  //     topic: "Workforce",

  //     EsgScore: props.riskEvaluator?.calculatedEsgDisclosure?.final_categories
  //       ? props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //           "Workforce"
  //         ] < 6.7
  //         ? "Low"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Workforce"
  //           ] < 13.5 &&
  //           props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Workforce"
  //           ] > 6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.calculatedEsgDisclosure?.final_categories[
  //             "Workforce"
  //           ] > 13.5 && "High"
  //       : "Low",
  //     sentimentScore: props.riskEvaluator?.sentimentScore?.category_values
  //       ? props.riskEvaluator?.sentimentScore?.category_values["Workforce"] <
  //         6.7
  //         ? "Low"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Workforce"] <
  //             13.5 &&
  //           props.riskEvaluator?.sentimentScore?.category_values["Workforce"] >
  //             6.7
  //         ? "Medium"
  //         : props.riskEvaluator?.sentimentScore?.category_values["Workforce"] >
  //             13.5 && "High"
  //       : "low",
  //     deviation: "Positive",
  //   },
  // ];
  return (
    <Grid
      md={12}
      style={{ marginTop: "16px", padding: "5px" }}
      component={Paper}
    >
      <EsgRiskSummary {...props} />

      <Grid>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ fontSize: "14px" }}>
            Topic Wise Summary
          </Typography>
          <Icon>more_vert</Icon>
        </div>
        <div>
          <Grid
            md={12}
            style={{ marginTop: "16px", padding: "5px" }}
            // component={Paper}
          >
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "10px",
                backgroundColor: "#EBF1F8",
                padding: "3px",
                borderRadius: "5px",
              }}
            >
              <Grid item md={1} xs={1} align="center">
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  SL.No.
                </Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <Typography style={{ fontSize: "13px" }}> Topics</Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Typography style={{ fontSize: "13px" }}>
                  ESG Disclosure Score
                </Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography style={{ fontSize: "13px", textAlign: "center" }}>
                  Sentiment Score
                </Typography>
              </Grid>
              <Grid item md={2} xs={2}>
                <Typography style={{ fontSize: "13px", textAlign: "center" }}>
                  {" "}
                  Deviation
                </Typography>
              </Grid>
            </Grid>
            {categoryWiseScore.map((row) => {
              return (
                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "10px",
                    padding: "5px",
                    // borderBottom: "1px solid grey",
                    borderRadius: "2px",
                    alignItems: "center",
                  }}
                >
                  <Grid align="center" item md={1} sm={1} xs={1}>
                    {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      > */}
                    {/* <div
                          className={
                            row.stat === "up"
                              ? classes.upColor
                              : row.stat === "flat"
                              ? classes.flatColor
                              : row.stat === "down"
                              ? classes.downColor
                              : null
                          }
                        >
                          <KeyboardArrowUpIcon className={classes.arrowUp} />
                        </div> */}
                    <Typography style={{ fontSize: "13px" }}>
                      {row.slNo}
                    </Typography>
                    {/* </div> */}
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      {row.topic}
                    </Typography>
                    {/* <Typography
                        style={{
                          fontSize: "12px",
                          marginLeft: "5px",
                          color: "grey",
                        }}
                      >
                        {row.time}
                      </Typography> */}
                  </Grid>

                  <Grid item md={3} sm={3} xs={3}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      {row.EsgScore}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <Typography
                      style={{ fontSize: "13px", textAlign: "center" }}
                    >
                      {row.sentimentScore}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <div style={{ textAlign: "center", padding: "0px" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          padding: "2px 5px",
                          borderRadius: "12px",
                          backgroundColor:
                            row.deviation === "Negative"
                              ? "#C45446"
                              : "#32A05F",
                          color: "#fff",
                        }}
                      >
                        {row.deviation}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              );
            })}
            {/* <TableContainer className={classes.container}>
                 <Table stickyHeader aria-label="sticky table">
                  <TableHead style={{ backgroundColor: "blue" }}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        console.log(row);
                        console.log(
                          row.stat === "up"
                            ? "up"
                            : row.stat === "flat"
                            ? "flat"
                            : row.stat === "down"
                            ? "down"
                            : null
                        );
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.title}
                          >
                            <StyledTableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className={
                                    row.stat === "up"
                                      ? classes.upColor
                                      : row.stat === "flat"
                                      ? classes.flatColor
                                      : row.stat === "down"
                                      ? classes.downColor
                                      : null
                                  }
                                >
                                  <KeyboardArrowUpIcon
                                    className={classes.arrowUp}
                                  />
                                </div>
                                <Typography>{row.name}</Typography>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Typography>{row.title}</Typography>
                              <Typography>{row.time}</Typography>
                            </StyledTableCell>
                            <StyledTableCell>{row.source}</StyledTableCell>
                            <StyledTableCell align="right">
                              {row.link}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Typography>{`>`}</Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer> */}
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default RiskSummaryTab;
