import {
  AppBar,
  Grid,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPanel/tabPanel";
import Assessment from "../esgDeclaration/assessment/assessment";
import ScoreChart from "./ScoreChart";
import OverallProfile from "./overallProfile";
import EsgScore from "./esgScore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

function EsgCompantProfile(props) {
  const companyProfileStyles = makeStyles((theme) => ({
    AppBar: { boxShadow: "none" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
  }));
  // const [profileTabNo, setProfileTabNo] = useState(0);
  const { setProfileTabNo } = props;
  const { profileTabNo } = props.brmData;

  const classes = companyProfileStyles();
  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Company Profile
          </Typography>
        </div>
        <div>
          <InfoOutlinedIcon style={{ color: "#3374b9", marginRight: "12px" , cursor:'pointer' }} />
          <ShareOutlinedIcon style={{ color: "#3374b9", marginRight: "5px" , cursor:'pointer'}} />
        </div>
      </div>
      <div style={{ height: "61vh" }}>
        <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={profileTabNo}
              onChange={(e, newVal) => {
                setProfileTabNo(newVal);
              }}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESG Score</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Overall</span>}
              />
            </Tabs>
          </AppBar>
        </MuiThemeProvider>
        <TabPanel value={profileTabNo} index={0}>
         <EsgScore />
        </TabPanel>
        <TabPanel value={profileTabNo} index={1}>
          <OverallProfile {...props} />
        </TabPanel>
      </div>
    </div>
  );
}

export default EsgCompantProfile;
