import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import ScoreChart from "./ScoreChart";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";

function EsgScore() {
  const TopicHeader = ({ topic, score }) => {
    
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid lightblue",
          marginTop:'10px'
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={getColoredLogo(topic)} alt="leaf" />
          <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
            {topic}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
            {score}
          </Typography>
        </div>
      </div>
    );
  };
  const TopicData = ({ topic, score }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop:'8px'
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
          >
            {topic}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
            {score}
          </Typography>
        </div>
      </div>
    );
  };

  const data = [
    {
      topic: "Environment",
      score: 28,
      subTopic: [
        { topic: "Climate Change", score: 10 },
        { topic: "Water Management", score: 6 },
        { topic: "Biodiversity", score: 5 },
        { topic: "Pollution Control", score: 3 },
      ],
    },
    {
      topic: "Social",
      score: 20,
      subTopic: [
        { topic: "Labor Rights", score: 6 },
        { topic: "Human Rights", score: 5 },
        { topic: "Community Development", score: 4 },
        { topic: "Diversity and Inclusion", score: 5 },
      ],
    },
    {
      topic: "Governance",
      score: 15,
      subTopic: [
        { topic: "Board Independence", score: 4},
        { topic: "Executive Compensation", score: 3 },
        { topic: "Shareholder Rights", score: 3 },
        { topic: "Risk Management", score: 2 },
      ],
    },
    {
      topic: "Business",
      score: 30,
      subTopic: [
        { topic: "Product Quality and Safety", score: 10 },
        { topic: "Customer Privacy and Data Protection", score: 6 },
        { topic: "Supply Chain Management", score: 28 },
        { topic: "Marketing and Advertising", score: 25 },
        { topic: "Innovation and Intellectual Property", score: 3},
      ],
    },
    {
      topic: "Human Capital",
      score: 7,
      subTopic: [
        { topic: "Work-Life Balance", score: 2 },
        { topic: "Employment Training & Development", score: 2 },
        { topic: "Diversity and Inclusion", score: 1 },
        { topic: "Employee engagement", score: 2 },
      ],
    },
  ];
  return (
    <div className="scroll" style={{height:'55vh',paddingBottom:'18px'}} >
      {" "}
      <Grid container md={12}>
        <Grid item md={6}>
          <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography>

          {data.map((data) => {
            return (
              <>
                <TopicHeader topic={data.topic} score={data.score} />
                {data.subTopic.map((sub) => (
                  <TopicData topic={sub.topic} score={sub.score} />
                ))}
              </>
            );
          })}
        </Grid>
        <Grid item md={6} >
          <ScoreChart />
        </Grid>
      </Grid>
    </div>
  );
}

export default EsgScore;
