import React from 'react'
import { connect } from "react-redux";
import RiskSurvey from "../../components/widgets/riskAssessment/riskSurvey";
import { getAssignedQuestions, getCorporateQuestions, submitAnswer } from "../../redux/actions/corporateSurvey/corporateSurveyActions";

function CorporateSurveyTaskCont(props) {
  return (
    <div>
        <RiskSurvey  {...props}/>
    </div>
  )
}

const mapStateToProps = (store) => ({
    login: store.login,
    brmData:store.brmData,
    corporateSurvey:store.corporateSurvey,
    riskEvaluator:store.riskEvaluator,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getCorporateQuestions: (token,organization_id) => {
      dispatch(getCorporateQuestions(token,organization_id));
    },
    submitAnswer: (token, organization_id,question_id,value) => {
      dispatch(submitAnswer(token, organization_id,question_id,value));
    },
    getAssignedQuestions: (token, organization_id) => {
      dispatch(getAssignedQuestions(token, organization_id));
    },
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CorporateSurveyTaskCont);
  