import React from "react";
import { connect } from "react-redux";
import {
  setBrmMainTabNo,
  setBrmSubTabNo,
  setControversyTabNo,
  setDeclarationTabNo,
  setProfileTabNo,
  shareSurvey,
} from "../../redux/actions/brm/BrmActions";
import BrmDetaails from "../../components/widgets/riskAssessment/brmDetaails";
import {
  getBarChartData,
  getCalculatedSurvey,
  getNewArticles,
  getSentimentCalculatedData,
  getTopicWiseData,
  submitRiskEvaluation,
} from "../../redux/actions/riskEvaluator/riskEvaluatorActions";
import {
  createOrganization,
  setOrganizationDetails,
  updateOrganization,
  viewDetailsOrganization,
} from "../../redux/actions/organization/organizationAction";
import { addMember, editMember, viewMember } from "../../redux/actions/member/member";

function BrmDetailsCont(props) {
  return (
    <div>
      <BrmDetaails {...props} />
    </div>
  );
}

export const mapStateToProps = (store) => ({
  login: store.login,
  brmData: store.brmData,
  corporateSurvey: store.corporateSurvey,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  setBrmSubTabNo: (payload) => {
    dispatch(setBrmSubTabNo(payload));
  },
  setBrmMainTabNo: (payload) => {
    dispatch(setBrmMainTabNo(payload));
  },
  setDeclarationTabNo: (payload) => {
    dispatch(setDeclarationTabNo(payload));
  },
  setControversyTabNo: (payload) => {
    dispatch(setControversyTabNo(payload));
  },
  setProfileTabNo: (payload) => {
    dispatch(setProfileTabNo(payload));
  },
  shareSurvey: (token, organization_id, email) => {
    dispatch(shareSurvey(token, organization_id, email));
  },
  getCalculatedSurvey: (token, organization_id) => {
    dispatch(getCalculatedSurvey(token, organization_id));
  },
  updateOrganization: (
    token,
    organization_id,
    name,logo,
    sectors,
    operation,
    no_of_employees,
    address,
    masterSectors
  ) => {
    dispatch(
      updateOrganization(
        token,
        organization_id,
        name,logo,
        sectors,
        operation,
        no_of_employees,
        address,
        masterSectors
      )
    );
  },
  viewDetailsOrganization: (token, organization_id) => {
    dispatch(viewDetailsOrganization(token, organization_id));
  },
  viewMember: (token, organization_id) => {
    dispatch(viewMember(token, organization_id));
  },
  editMember: (token, user_id, organization_id,role,category) => {
    dispatch(editMember(token, user_id, organization_id,role,category));
  },
  addMember: (
    token,
    bank_id,
    organization_id,
    name,
    email,
    role,
    category,
    type,
    url
  ) => {
    dispatch(
      addMember(
        token,
        bank_id,
        organization_id,
        name,
        email,
        role,
        category,
        type,
        url
      )
    );
  },
  setOrganizationDetails: (payload) => {
    dispatch(setOrganizationDetails(payload));
  },

  getSentimentCalculatedData: (organization_name) => {
    dispatch(getSentimentCalculatedData(organization_name));
  },
  getNewArticles: () => {
    dispatch(getNewArticles());
  },

  getBarChartData: () => {
    dispatch(getBarChartData());
  },

  getTopicWiseData: () => {
    dispatch(getTopicWiseData());
  },
  submitRiskEvaluation: (token, organization_id, evaluation_value, comment) => {
    dispatch(
      submitRiskEvaluation(token, organization_id, evaluation_value, comment)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrmDetailsCont);
