import {
  GET_CALCULATED_SURVEY,
  RESET_RISK_EVALUATOR,
  SET_CALCULATED_SURVEY,
  SET_CALCULATED_SENTIMENT,
  SET_CALCULATED_SENTIMENT_TOPIC_WISE,
  SET_CALCULATED_SENTIMENT_BAR_GRAPH,
  SET_CALCULATED_SENTIMENT_ARTICLE,
  SET_SENTIMENT_SOCRE_DATA,
} from "../../../constants/riskEvaluatorConstants";

const initial_state = {
  riskEvaluator: "",
  calculatedEsgDisclosure: "",
  sentimentRiskWiseData: "",
  sentimentBarGraph: "",
  sentimentArticle: "",
  sentimentScore: "",
};

export default function riskEvaluatorReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CALCULATED_SURVEY:
      return (state = { ...state, calculatedEsgDisclosure: action.payload });
    case SET_CALCULATED_SENTIMENT_TOPIC_WISE:
      return (state = { ...state, sentimentRiskWiseData: action.payload });
    case SET_CALCULATED_SENTIMENT_BAR_GRAPH:
      return (state = { ...state, sentimentBarGraph: action.payload });
    case SET_CALCULATED_SENTIMENT_ARTICLE:
      return (state = { ...state, sentimentArticle: action.payload });
    case SET_SENTIMENT_SOCRE_DATA:
      return (state = { ...state, sentimentScore: action.payload });
    case RESET_RISK_EVALUATOR:
      return (state = initial_state);
    default:
      return state;
  }
}
