import encrypt from "../shared/sharedActions";
import UNIVERSAL from "../../../config/config";
import {
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
  SET_SELECTED_SECTOR_INDUSTRIES,
} from "../../../constants/brmConstants";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";

export function createOrganization(
  token,
  bankId,
  name,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors
) {
  return (dispatch) => {
    var sectorData = [],
      sectorData2 = [];

    Array.isArray(masterSectors) &&
      masterSectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector.industry_data.map((industry, index2) => {
          if (industry.isSelected) {
            sectorData[index].industry.push(industry._id);
          }
          return true;
        });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      name: name,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address,
    });
    // console.log(
    //   "create api ",
    //   token,
    //   bankId,
    //   name,
    //   logo,
    //   sectors,
    //   operation,
    //   no_of_employees,
    //   address,
    //   masterSectors
    // );
    var formData = new FormData();
    formData.append("data", data);
    formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/organization/create_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewOrganization(token, bankId));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function updateOrganization(
  token,
  organization_id,
  name,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors
) {
  return (dispatch) => {
    var sectorData = [],
      sectorData2 = [];

    masterSectors &&
      masterSectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector &&
          sector.industry_data.map((industry, index2) => {
            if (industry.isSelected) {
              sectorData[index].industry.push(industry._id);
            }
            return true;
          });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });
    const data = encrypt({
      "user-token": token,
      organization_id,
      name: name,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address: address[0],
    });
    var formData = new FormData();

    formData.append("data", data);
    logo && formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/organization/update_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewDetailsOrganization(token, organization_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const setOrganizationsList = (payload) => {
  return {
    type: SET_ORGANIZATIONS_LIST,
    payload,
  };
};
export function viewOrganization(token, bankId) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
    });
    return fetch(UNIVERSAL.BASEURL + "/organization/view_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganizationsList(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// export function viewOrganization(token, bankId) {
//   return (dispatch) => {
//     dispatch(set_loader());
//     const data = encrypt({
//       "user-token": token,
//       bank_id: bankId,
//     });
//     return fetch(UNIVERSAL.BASEURL + "/organization/view_organizations", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json",
//       },
//       body: JSON.stringify({
//         data: data,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           // dispatch(setOrganizationDetails(responseJson.result));
//         }
//         dispatch(unset_loader());
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// }

export function setSelectedSectorIndustries(payload) {
  return {
    type: SET_SELECTED_SECTOR_INDUSTRIES,
    payload: payload,
  };
}
export function setOrganizationDetails(payload) {
  return {
    type: SET_ORGANIZATION_DETAILS,
    payload: payload,
  };
}

export function viewDetailsOrganization(token, organizationId) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      organization_id: organizationId,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/organization/view_organization_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
