import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import leaf from "../../../images/Icon.png";
import network from "../../../images/Icon-1.png";
import piece from "../../../images/Icon-3.png";
import document from "../../../images/Document.png";
import {
  financialInsights,
  getColoredLogo,
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../utils/riskUtils/riskUtils";
function OverallProfile(props) {
  useEffect(() => {

    return ()=>{
      props.setProfileTabNo(0)
    }
  }, []);
  const OverallRiskCard = ({ topic, heading, description }) => {
    return (
      <Grid item xs={4}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={getColoredLogo(topic)} alt="logo" />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              {heading}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
              {description}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  const FinancialInsightsCard = ({ heading, description }) => {
    return (
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img style={{ width: "80px" }} src={document} alt="logo" />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              {heading}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
              {description}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <div className="scroll" style={{ height: "56vh", paddingBottom: 24 }}>
      <div>
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Risk Area
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
            These are the area of risks & edentification of emerging ESG risks
            and potential regulatory changes. With impact percentage total of
          </Typography>
        </div>

        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {riskArea.map((topic) => (
              <OverallRiskCard
                topic={topic.topic}
                heading={topic.heading}
                description={topic.description}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 15 }}>
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Strong Area
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
            These are the area of risks & edentification of emerging ESG risks
            and potential regulatory changes. With impact percentage total of
          </Typography>
        </div>

        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {strongAreas.map((topic) => (
              <OverallRiskCard
                topic={topic.topic}
                heading={topic.heading}
                description={topic.description}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 15 }}>
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Improvement areas
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
            These are the area of risks & edentification of emerging ESG risks
            and potential regulatory changes. With impact percentage total of
          </Typography>
        </div>

        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {improvementAreas.map((topic) => (
              <OverallRiskCard
                topic={topic.topic}
                heading={topic.heading}
                description={topic.description}
              />
            ))}
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 15 }}>
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Financial/Repayment Insights
          </Typography>
        </div>

        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {financialInsights.map((topic) => (
              <FinancialInsightsCard
                heading={topic.heading}
                description={topic.description}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default OverallProfile;
