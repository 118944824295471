import { makeStyles } from "@material-ui/core/styles";
export const LoginUseStyles = makeStyles({
  root: {
    width: 650,
    border: "none",
    height: 450,
    position: "relative",
  },

  cardHeader: {
    fontWeight: 600,
  },
  cardContent: {
    paddingTop: 0,
  },
  divider: {
    backgroundColor: "#F1F3F4",
    marginBottom: 15,
  },
  labelText: {
    color: "#828282",
    fontSize: "12px",
  },
  labelCheckbox: {
    fontSize: "12px !important",
    color: "#DADBE6",
    display: "block",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute", bottom: 20, right: 50 
  },
  loginGridContainer: {
    backgroundColor: "#F5F6F8",
  },
  loginGridItem1: {
    zIndex: 100,
  },
  loginGridDiv: {
    display: "flex",
    position: "fixed",
    marginLeft: "20px",
    marginTop: "25px",
  },
  loginGridAvatar: {
    marginTop: "200px",
  },
  loginGridTypography: {
    color: "white",
  },
  loginGridbody1: {
    color: "white",
    fontSize: "18px",
  },
  loginGridbody2: {
    color: "rgba(255, 255, 255, 0.45)",
  },
  loginGridleaf: {
    marginTop: "70px",
    marginLeft: "80px",
    alignSelf: "center",
  },
  loginGridSideLeft: {
    position: "absolute",
    top: 450,
    left: 10,
    width: "70px",
  },
  loginGridSideRight: {
    position: "absolute",
    top: 120,
    left: 255,
    width: "70px",
  },
  loginGridPolicy: {
    marginTop: "auto",
  },
  loginGridUl: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-evenly",
    textDecoration: "none",
    listStyle: "none",
    fontSize: 14,
  },
  loginGridAnchor: {
    textDecoration: "none",
    color: "#ffff !important",
  },
  loginGridItem2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginGridCardText: {
    width: "400px"
  },
  loginGridInputAdorment:{
    cursor: "pointer"
  }
});
