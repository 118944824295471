var UNIVERSAL = {
    BASEURL: process.env.React_App_BASEURL,
    // BASEURL:"https://frontend-dot-imgrows-test.el.r.appspot.com/api",
    //  BASEURL: "https://esg-prl.herokuapp.com/api",
    //BASEURL :  "https://api.imgrows.com/api",
    // PDF_MICROSERVICE: process.env.React_App_PDF_MICROSERVICE,
    // URL_FOR_SOCKET: process.env.React_App_URL_FOR_SOCKET
  };
  
  export default UNIVERSAL;
  